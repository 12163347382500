
// Page jobs
//-----------------------------------------

.ts-jobs--table {
    
    tr:nth-child(n+2) td {
        border-top: 1px solid #ddd;
    }
    
    tr.ts--table-hidden-tr {
        
        td {
            padding-right: 0;
            padding-left: 0;
            padding-bottom: 0;
            border-top: 1px solid #fff;
            
            &:before {
                display: none;
            }
        }
    }
    
    tr {
        background: transparent;
        @include transition(ease, .15s);
        
        td {
            border-bottom: 1px solid transparent;
        }
        
        &.active {
            @for $i from 1 through length($ts-mc-all) {
                &.ts-mc-#{$i}--bc {
                   background: lighten(nth($ts-mc-all, $i), 30%);
                   color: darken(nth($ts-mc-all, $i), 20%);
                }
            }
            
            td {
                border-bottom: 1px solid #ddd;
            }
        }
    }
    
    .ts--table-hidden-box {
        height: auto;
        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        box-shadow: inset 0px -5px 10px 0px rgba(0, 0, 0, 0.07);
        @include transition(ease, .4s);
        
        &.active {
            max-height: 999px;
            visibility: visible;
            @include transition(ease-out, 1.2s);
        }
        
        .ts--table-hidden-right,
        .ts--table-hidden-left {
            box-sizing: border-box;
            padding: 20px 45px;
            width: 50%;
        }
        
        .ts--table-hidden-right {
            float: right;
        }
        
        .ts--table-hidden-left {
            float: left;
            border-right: 1px solid #ddd;
            background: #fafafa;
        }
        
        .ts--table-hidden-title {
            display: block;
            margin-bottom: 10px;
            border-bottom: 1px solid #ddd;
            text-align: right;
            background: transparent;
            
            @for $i from 1 through length($ts-mc-all) {
                &.ts-mc-#{$i}--bc {
                   color: darken(nth($ts-mc-all, $i), 20%);
                }
            }
        }
        
        .ts--table-hidden-content {
            margin-bottom: 20px;
            font-size: 1em;
            
            a {
                color: #1190bc;
                text-decoration: underline;
                
                &:hover {
                    color: #0d6e8f;
                }
            }
        }
        
        .ts--table-button-close {
            padding-right: 45px;
            padding-left: 45px;
            background: #b9b9b9;
            
            &:hover {
                background: #a5a5a5;
            }
        }
    }
}