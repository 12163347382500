
// Side Bar
//-----------------------------------------

#ts-side--con {
    z-index: 9999;
    overflow: hidden;
    position: fixed;
    width: 400px;
    top: 250px;
    left: 0;
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    
    h3 {
        font-weight: normal;
    }
    
    #side--title {
        font-size: 0.95em;
        height: 45px;
        line-height: 45px;
        border-bottom: 1px solid #cccccc;
    }
    
    #side--title.icon-nav:before {
        float: right;
        width: 45px;
        height: 45px;
        line-height: 48px;
        margin-left: 3%;
        text-align: center;
        font-size: 1.8em;
        color: $ts-mc-b;
        background: $ts-mc-d;
    }
    
    #side--nav {
        float: right;
        width: 95%;
        padding: 2.5%;
        
        li {
            display: inline-block;
            width: 33.333%;
            text-align: center;
            
            .side--nav-item {
                padding: 10% 5%;
                color: $ts-mc-d;
                @include transition(ease, 0.1s);
                
                .item--icon {
                    display: block;
                    font-size: 3.5em;
                    height: 60px;
                    @include transition(ease, 0.15s);
                }
                
                .item--content {
                    display: block;
                    font-size: 0.9em;
                }
            }
            
            a:hover > .side--nav-item, a:focus > .side--nav-item {
                background: lighten( $ts-mc-b, 5% );
                .item--icon {
                    font-size: 3.8em;
                }
            }
        }
    }
    
    #side--buttons {
        display: inline-block;
        width: 90%;
        margin: 0 0 2.5% 0;
        padding: 5%;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        
        a {
            display: inline-block;
            width: 48%;
            height: 42px;
            line-height: 42px;
            text-align: center;
            color: #fff;
            @include transition(ease, 0.15s);
        }
        
        :first-child {
            background: $ts-mc-1;
            margin-left: 2.5%;
        }
        
        :first-child:hover, :first-child:focus {
            background: darken( $ts-mc-1, 10% );
        }
        
        :last-child {
            background: $ts-mc-3;
        }
        
        :last-child:hover, :last-child:focus {
            background: darken( $ts-mc-3, 10% );
        }
    }
    
    #side--news {
        display: block;
        padding: 0 5% 2.5%;
        border-bottom: 1px solid #cccccc;
        
        ul {
            display: inline-block;
            
            li {
                width: 100%;
                line-height: 25px;
                font-size: 0.95em;
                a:hover, a:focus {
                    text-decoration: underline;
                }
            }
            
            li:before {
                content: "\2022";
                position: relative;
                top: 1px;
                margin-left: 1%;
                font-size: 1.4em;
                color: #4a4a4a;
            }
        }
    }
    
    #side--search {
        padding: 7.5% 5%;
        height: 35px;
        
        form {
            position: relative;
            input[type="text"] {
                position: absolute;
                top: 0;
                right: 0;
                width: 75%;
                height: 35px;
                padding: 0 5% 0 20%;
                line-height: 35px;
                border-radius: 4px;
                font-family: 'Alef', sans-serif;
                font-size: 1em;
                color: $ts-mc-d;
                border: 1px solid #cccccc;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            }
            
            #search--type-submit {
                position: absolute;
                top: 1px;
                left: -1px;
                width: 20%;
                height: 35px;
                line-height: 35px;
                color: $ts-mc-b;
                background: $ts-mc-d;
                border-radius: 4px 0 0 4px;
                input[type="submit"] {
                    z-index: 1;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    padding-left: 15%;
                    height: 35px;
                    color: $ts-mc-b;
                    font-size: 1.05em;
                    text-align: left;
                    font-family: 'Alef', sans-serif;
                    @include transition(ease, 0.15s);
                }
            }
            
            #search--type-submit:before {
                content: "\f002";
                z-index: 0;
                position: absolute;
                width: 45%;
                padding-left: 55%;
                text-align: center;
                @include transition(ease, 0.15s);
            }
            
            #search--type-submit:hover > input[type="submit"],
            #search--type-submit:focus > input[type="submit"]{
                padding-left: 25%;
                color: transparent;
            }
            
            #search--type-submit:hover:before,
            #search--type-submit:focus:before{
                width: 100%;
                padding-left: 0%;
                font-size: 1.3em;
            }
            
            label {
                z-index: 9;
                position: absolute;
                right: 0;
                top: 0;
                background: rgba(255, 255, 255, 0);
                color: #7d7d7d;
                line-height: 10px;
                margin-right: 5%;
            }
        }
    }
}

// On active

#ts-menu-shortcuts-but, #ts-menu-top-but {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #333333;
    border: 2px solid #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

#ts-menu-shortcuts-but:before, #ts-menu-top-but:before {
    content: '\e00c';
    font-family: 'FontAwesome';
    display: block;
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #fff;
    text-align: center;
    font-size: 20px;
}

#ts-menu-shortcuts-but.active:before, #ts-menu-top-but.active:before {
    content: '\f00d';
    font-size: 25px;
}

#ts-menu-top-but {
    left: 0;
    right: 20px;
}

#ts-menu-shortcuts-but:before {
    content: '\f124';
}   

#ts-menu-top-but:before {
    content: '\f0c9';
}

.ts-headline--strip {
    position: relative;
    display: inline-block;
    height: 500px;
    width: 100%;
    margin-bottom: 30px;
}

#search--type-free.ts-input {
    margin: 0;
}