
// Header
//-----------------------------------------

header {
    z-index: 10000;
    display: block;
    width: 100%;
    background: #fff;
    position: fixed;
}

#header-top--strip {
    display: block;
    height: 90px;
    margin-top: 20px;
    @include transition(ease, 0.25s);
    
    #strip-logo {
        float: right;
        width: 300px;
    }
    
    #strip-gotrans {
        float: left;
        padding: 15px 0;
        width: 105px;
        overflow: hidden;
        font-size: .9em;
        text-align: right
    }
}

#header-top--menu {
    height: 45px;
    @include transition(ease, 0.25s);
    
    li {
        position: relative;
        width: 10.61%;
        margin: 0 0.25%;
        text-align: center;
        
        .menu-button {
            display: block;
            position: relative;
            width: 100%;
            height: 35px;
            font-weight: 700;
        }
        
        .menu-button:after {
            content: '';
            z-index: -1;
            width: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 6px;
            margin-top: 4px;
            @include skew(-30deg, 0deg);
        }
        
        @for $i from 1 through length($ts-mc-all) {
            a.ts-mc-#{$i}:hover, a.ts-mc-#{$i}:focus  {
                color: darken( nth($ts-mc-all, $i), 20% );
            }
        }
        
        @for $i from 1 through length($ts-mc-all) {
            a.ts-mc-#{$i}:after {
                background-color: nth($ts-mc-all, $i);
            }
        }
        
        @for $i from 1 through length($ts-mc-all) {
            .menu-sub--title.ts-mc-#{$i} {
                color: nth($ts-mc-all, $i);
            }
        }
        
        .menu-sub {
            visibility: hidden;
            opacity: 0;
            z-index: 9;
            width: 300%;
            position: absolute;
            top: 100%;
            right: 0;
            padding: 10%;
            background: #fff;
            border-bottom-width: 4px !important;
            border-bottom-style: solid !important;
            box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
            @include transition(ease, 0.25s);
            
            .menu-sub--title {
                    font-size: 1.2em;
                    text-align: right;
                    padding-bottom: 3px;
                    margin-bottom: 8px;
                    border-bottom: 1px solid #ebe3e1;
            }
            
            li {
                width: 48%;
                padding: 0 2% 0 0;
                height: 30px;
                line-height: 30px;
                margin: 0;
                text-align: right;
                
                a {
                    display: block;
                    width: 100%;
                    font-weight: 400;
                }
                
                :hover, :focus {
                    text-decoration: underline;
                }
                
                :before {
                    content: '\f104';
                    color: #5c5c5c;
                    position: relative;
                    left: 4%;
                    @include transition(ease, 0.15s);
                }
                
                :hover:before, :focus:before {
                    left: 2%;
                }
            }
        }
    }
    
    li:hover .menu-sub, li:focus .menu-sub, li.active .menu-sub {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
    }
}

#header-top--nav {
    opacity: 1;
    display: block;
    height: 35px;
    line-height: 35px;
    font-size: 0.9em;
    background: #fbfbfb;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    
    a:hover, a:focus {
        text-decoration: underline;
    }
}

#header-top--nav.active {
    height: 0;
    opacity: 0;
    overflow: hidden;
}