
// Page contact
//-----------------------------------------

@for $i from 1 through length($ts-mc-all) {
    .page-title.ts-mc-#{$i}--bc {
        background: darken(nth($ts-mc-all, $i), 10%);
    }
}

.ts-contact--map {
    overflow: hidden;
    float: right;
    width: 30%;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);    

    iframe {
        float: right;
        width: 100%;
        height: 200px;
    }
    
    .ts-contact--map-content {
        clear: both;
        padding: 5px;
     
        @for $i from 1 through length($ts-mc-all) {
            &.ts-mc-#{$i}--bc {
                background: lighten(nth($ts-mc-all, $i), 25%);
            }
        }   
    }
}

.ts-contact--content {
    float: left;
    width: 67.5%;
}

.ts-contact--form {
    margin-top: 20px;
    
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}