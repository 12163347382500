
// Footer
//-----------------------------------------

footer {
    height: 250px;
}

.footer-nav {
    display: flex;
    align-self: center;
    height: 100%;
    padding: 0 5%;
    
    ul {
        display: flex;
        align-self: center;
        height: 35%;
        
        li {
            position: relative;
            height: 80%;
            padding: 5%;
            margin: 0 10px;
            background: #fff;
            border-radius: 5px;
            overflow: hidden;
            
            img {
                height: 100%;
                width: auto;
                display: block;
            }
        }
    }
}