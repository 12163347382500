
// Default
//-----------------------------------------

// Fonts

@import 'https://fonts.googleapis.com/css?family=Alef:400,700&subset=hebrew';
@import 'fonts/Font-Awesome/font-awesome.min.css';
@import 'fonts/Fontastic/styles.css';

// Generals

* {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}

*:-webkit-scrollbar {
     display: none;
}

::-webkit-scrollbar {
    width: 0px; 
    background: transparent;
}
::-webkit-scrollbar-thumb {
    width: 0px; 
    background: transparent;
}

:before, :after {
    font-family: 'FontAwesome';
    -webkit-font-smoothing: antialiased;
}

body {
    direction: rtl;
    font-weight: 400;
    font-family: 'Alef', sans-serif;
    -webkit-font-smoothing: antialiased;
}

.ts-cf:after {
    content: "";
    display: block;
    clear: both;
}

.ts-main--width {
    display: block;
    position: relative;
    max-width: 1500px;
    margin: 0 auto;
}

.ts-main--width-right {
    display: inline-block;
    width: 70%;
}

.ts--text-bold {
    font-weight: 700;
}

.ts-align-right {
    text-align: right;
}

.page-title {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 60px;
    font-size: 1.5em;
    color: #fff;
}

.ts-padding-tb {
    display: block;
    padding: 15px 0;
}

.ts-mobile-show {
    display: none !important;
}
    
fieldset {
    border: none;
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

textarea, input, select {
    font-family: 'Alef', sans-serif;
    font-size: 1em;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    -webkit-appearance: none;
    line-height: 1em;
    background: transparent;
}

textarea {
    overflow-x: hidden;
}

a, label {
    outline: none;
    cursor: pointer;
}

b {
    outline: none;
}

textarea:focus, input:focus, button:focus{
    outline: none;
}

input[type="submit"] {
    cursor: pointer;
}

ul {
    list-style: none;
}

li {
    display: inline-block;
    float: right;
}

h1 {
    font-size: 1.7em;
}

h2 {
    font-size: 1.5em;
}

h3, h4, h5, h6 {
    font-size: 1.3em;
}

a {
    text-decoration: none;
    color: black;
}

p {
    color: #333333;
    font-size: 1em;
    line-height: 1.5em;
    text-align: right;
}

img {
    width: 100%;
}

main {
    min-height: 100vh;
}

.ab-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
}

.ts-main--border-line {
    display: block;
    float: right;
    width: 100%;
    height: 0;
    border-bottom: 1px solid #cccccc;
}

.comment {
    color: #f7556d !important;
}

.input-error {
    border-color: rgba(247, 85, 109, 0.6) !important;
}

.submit-error {
    background: #f7556d !important;
    color: #fff !important;
}

.block-border {
    overflow: hidden;
    float: right;
    width: calc(100% - 2px);
    margin-bottom: 20px;
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);    
}

.ts-info-page.block-border {
    width: calc(100% - 12px);
    margin: 0 6px;
}

.block-border:last-child {
    margin-bottom: 0px;
}

.block-border.half-width {
    width: calc(48.5% - 2px);
}

.block-border.half-width:nth-child(odd) {
    margin-left: 3%;
}

.block-border.half-width {
    text-align: center;
}

.block-border--title {
    border-bottom: 1px solid #cccccc;
    padding: 5px 20px;
}

.block-border--content {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    font-size: .95em;

    img {
        width: auto;
    }
}

.block-border--content a {
    color: #1190bc;
    text-decoration: underline;
}

.block-border--content a:hover {
    color: #0d6e8f;
}

.ts-mc-gray--bc {
    background: $ts-mc-gray--bc;
}

// Animations

.an-ease {
    -webkit-transform: translateZ(0);
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.an-ju {
    transition: cubic-bezier(0.84, 0.08, 0.14, 1.22) 0.35s;
    -webkit-transition: cubic-bezier(0.84, 0.08, 0.14, 1.22) 0.35s;
    -moz-transition: cubic-bezier(0.84, 0.08, 0.14, 1.22) 0.35s;
    -ms-transition: cubic-bezier(0.84, 0.08, 0.14, 1.22) 0.35s;
    -o-transition: cubic-bezier(0.84, 0.08, 0.14, 1.22) 0.35s;
}

.an-ju-mid {
    transition: ease 0.4s;
    -webkit-transition: ease 0.4s;
    -moz-transition: ease 0.4s;
    -ms-transition: ease 0.4s;
    -o-transition:  ease 0.4s;
}

.an-ju-fast {
    transition: ease 0.15s;
    -webkit-transition: ease 0.15s;
    -moz-transition: ease 0.15s;
    -ms-transition: ease 0.15s;
    -o-transition:  ease 0.15s;
}

.an-ju-v-fast {
    transition: ease 0.05s;
    -webkit-transition: ease 0.05s;
    -moz-transition: ease 0.05s;
    -ms-transition: ease 0.05s;
    -o-transition:  ease 0.05s;
}

// Loader

.loader--element {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    margin: 30px;
    color: #2a75bb;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}

// Screen darker

.screen-dark {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0);
    transition: ease 0.15s;
    -webkit-transition: ease 0.15s;
    -moz-transition: ease 0.15s;
    -ms-transition: ease 0.15s;
    -o-transition:  ease 0.15s;
}

.screen-dark.active {
    z-index: 9998;
    background: rgba(0, 0, 0, 0.6);
}

// Main

main {
    overflow-x: hidden;
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 30px;
}

main:after {
    content: "";
    background: url(../img/background.png);
    background-size: 160px;
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1; 
}

.ts-filter-gray {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%);
}

.ui-helper-hidden-accessible {
    display: none !important;
}

.goToTop {
    background: #2c2b2d;
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    font-size: 20px;
}

.ui-tooltip {
    display: inline;
    border: 0px solid gainsboro;
	color: white;
	background: #2c2b2d;
	border-radius: 0px;
	box-shadow: none;
	font-size: 0.9em;
}