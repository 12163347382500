// Page calendar
//-----------------------------------------

// resets!


.clendario,
.clendarioTitle,
.clendarioLast,
.clendarioThisMunth,
.clendarioNext,
.clendarioTitle img,
.clendarioDaysRow,
.clendarioMunthDays {
    width: auto !important;
    margin: 0 !important;
    float: none !important;
    color: #000 !important;
    font-size: 1em !important;
    font-weight: normal !important;
    background: transparent !important;
}

.clendario {
    overflow: scroll;
}

.clendarioDaysRow, 
.clendarioMunthDays {
    min-width: 800px;
    
}

.clendarioTitle,
.clendarioDaysRow,
.clendarioMunthDays {
    display: table;
    overflow: hidden;
    box-sizing: border-box;
    width: 100% !important;
    margin-bottom: 20px !important;
    padding: 10px 20px;
    background: #fff !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
}

.clendarioDaysRow {
    background: #efefef !important;
}

.clendarioLast,
.clendarioThisMunth,
.clendarioNext,
.dayTop {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.clendarioThisMunth {
    font-size: 1.4em !important;
}

.clendarioLast {
    text-align: right;
}

.clendarioNext {
    text-align: left;
}


.clendarioMunthDays {
    padding: 0;
}

.dayBox {
    overflow: scroll;
    box-sizing: border-box;
    padding: 10px;
    float: right !important;
    width: 14.28% !important;
    height: 200px;
    border: 5px solid #fff;
    box-shadow: 0px 0px 1px 1px #ddd;
}

.curantEvent {
    background: rgb(55, 152, 212);
    padding: 5px 10px;
    line-height: 1.2em;
    margin-top: 10px;
}