.slideshow-open .slideshow {
  z-index: 999999999;  
}

.ts-galleries {
  width: 100%;
  float: right;
  display: flex;
  flex-wrap: wrap;
}

.grid-gallery {
  width: 100%;
}

.grid li:hover figcaption {
  background: #1e6c81 !important;
}

.grid figcaption {
  border-bottom: none !important;
}

.grid figure {
  overflow: hidden;
  margin: 30px 15px 0 !important;
  padding: 0 !important;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
}

.slideshow figure {
  border-radius: 10px;
}

.imageFolderWrapper {
  width: 33.333%;
  box-sizing: border-box;
  padding: 30px 15px 0;  
}

.imageFolder {
  overflow: hidden;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
}

.imageFolderImage {
  overflow: hidden;
  height: 200px;
  background: #ddd;
  img {
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}

.imageFolderTitle {
  border-top: 1px solid #ddd;
  padding: 10px
}

.imageFolderWrapper:hover .imageFolderTitle {
  color: #fff;
  background: #1e6c81;
}

@media screen and (max-width: 1280px) {
  .imageFolderWrapper {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .imageFolderWrapper {
    width: 100%;
  }

  .imageFolderImage {
    height: 150px;
  }
}