
// Share panel
//-----------------------------------------

.ts-share {
    float: right;
    width: 100%;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #cccccc;
    
    > * {
        display: inline-block;
        vertical-align: middle;
    }
    
    .ts-share--buttons {
        font-size: 18px;
        background: transparent;
        
        ul {
            display: block;
            width: 100%;
            
            li {
                margin-left: 10px;
            }
        }
        @for $i from 1 through length($ts-mc-all) {
            &.ts-mc-#{$i}--bc a i {
                color: darken(nth($ts-mc-all, $i), 25%);
            }
        }   
    }
    
    .ts-share--facebook {
        float: left;
    }
}