
// Mixin
//-----------------------------------------

@mixin translate($x, $y) {
  -webkit-transform: translate($x,$y);
     -moz-transform: translate($x,$y);
      -ms-transform: translate($x,$y);
       -o-transform: translate($x,$y);
          transform: translate($x,$y);
}


@mixin skew($x, $y) {
  -webkit-transform: skew($x,$y);
     -moz-transform: skew($x,$y);
      -ms-transform: skew($x,$y);
       -o-transform: skew($x,$y);
          transform: skew($x,$y);
}

@mixin rotate($x) {
  -webkit-transform: rotate($x);
     -moz-transform: rotate($x);
      -ms-transform: rotate($x);
       -o-transform: rotate($x);
          transform: rotate($x);
}

@mixin transform($x) {
  -webkit-transform: $x;
     -moz-transform: $x;
      -ms-transform: $x;
       -o-transform: $x;
          transform: $x;  
}


@mixin transition($ease, $s) {
  -webkit-transition: $ease $s;
     -moz-transition: $ease $s;
      -ms-transition: $ease $s;
      -o-transition:  $ease $s;
          transition: $ease $s;
}