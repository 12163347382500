@import 'https://fonts.googleapis.com/css?family=Alef:400,700&subset=hebrew';
@import url(fonts/Font-Awesome/font-awesome.min.css);
@import url(fonts/Fontastic/styles.css);
* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased; }

*:-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar {
  width: 0px;
  background: transparent; }

::-webkit-scrollbar-thumb {
  width: 0px;
  background: transparent; }

:before, :after {
  font-family: 'FontAwesome';
  -webkit-font-smoothing: antialiased; }

body {
  direction: rtl;
  font-weight: 400;
  font-family: 'Alef', sans-serif;
  -webkit-font-smoothing: antialiased; }

.ts-cf:after {
  content: "";
  display: block;
  clear: both; }

.ts-main--width {
  display: block;
  position: relative;
  max-width: 1500px;
  margin: 0 auto; }

.ts-main--width-right {
  display: inline-block;
  width: 70%; }

.ts--text-bold {
  font-weight: 700; }

.ts-align-right {
  text-align: right; }

.page-title {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 60px;
  font-size: 1.5em;
  color: #fff; }

.ts-padding-tb {
  display: block;
  padding: 15px 0; }

.ts-mobile-show {
  display: none !important; }

fieldset {
  border: none; }

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: ''; }

textarea, input, select {
  font-family: 'Alef', sans-serif;
  font-size: 1em;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  -webkit-appearance: none;
  line-height: 1em;
  background: transparent; }

textarea {
  overflow-x: hidden; }

a, label {
  outline: none;
  cursor: pointer; }

b {
  outline: none; }

textarea:focus, input:focus, button:focus {
  outline: none; }

input[type="submit"] {
  cursor: pointer; }

ul {
  list-style: none; }

li {
  display: inline-block;
  float: right; }

h1 {
  font-size: 1.7em; }

h2 {
  font-size: 1.5em; }

h3, h4, h5, h6 {
  font-size: 1.3em; }

a {
  text-decoration: none;
  color: black; }

p {
  color: #333333;
  font-size: 1em;
  line-height: 1.5em;
  text-align: right; }

img {
  width: 100%; }

main {
  min-height: 100vh; }

.ab-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%); }

.ts-main--border-line {
  display: block;
  float: right;
  width: 100%;
  height: 0;
  border-bottom: 1px solid #cccccc; }

.comment {
  color: #f7556d !important; }

.input-error {
  border-color: rgba(247, 85, 109, 0.6) !important; }

.submit-error {
  background: #f7556d !important;
  color: #fff !important; }

.block-border {
  overflow: hidden;
  float: right;
  width: calc(100% - 2px);
  margin-bottom: 20px;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1); }

.ts-info-page.block-border {
  width: calc(100% - 12px);
  margin: 0 6px; }

.block-border:last-child {
  margin-bottom: 0px; }

.block-border.half-width {
  width: calc(48.5% - 2px); }

.block-border.half-width:nth-child(odd) {
  margin-left: 3%; }

.block-border.half-width {
  text-align: center; }

.block-border--title {
  border-bottom: 1px solid #cccccc;
  padding: 5px 20px; }

.block-border--content {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  font-size: .95em; }
  .block-border--content img {
    width: auto; }

.block-border--content a {
  color: #1190bc;
  text-decoration: underline; }

.block-border--content a:hover {
  color: #0d6e8f; }

.ts-mc-gray--bc {
  background: #909090; }

.an-ease {
  -webkit-transform: translateZ(0);
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }

.an-ju {
  transition: cubic-bezier(0.84, 0.08, 0.14, 1.22) 0.35s;
  -webkit-transition: cubic-bezier(0.84, 0.08, 0.14, 1.22) 0.35s;
  -moz-transition: cubic-bezier(0.84, 0.08, 0.14, 1.22) 0.35s;
  -ms-transition: cubic-bezier(0.84, 0.08, 0.14, 1.22) 0.35s;
  -o-transition: cubic-bezier(0.84, 0.08, 0.14, 1.22) 0.35s; }

.an-ju-mid {
  transition: ease 0.4s;
  -webkit-transition: ease 0.4s;
  -moz-transition: ease 0.4s;
  -ms-transition: ease 0.4s;
  -o-transition: ease 0.4s; }

.an-ju-fast {
  transition: ease 0.15s;
  -webkit-transition: ease 0.15s;
  -moz-transition: ease 0.15s;
  -ms-transition: ease 0.15s;
  -o-transition: ease 0.15s; }

.an-ju-v-fast {
  transition: ease 0.05s;
  -webkit-transition: ease 0.05s;
  -moz-transition: ease 0.05s;
  -ms-transition: ease 0.05s;
  -o-transition: ease 0.05s; }

.loader--element {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  margin: 30px;
  color: #2a75bb;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%); }

.screen-dark {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: transparent;
  transition: ease 0.15s;
  -webkit-transition: ease 0.15s;
  -moz-transition: ease 0.15s;
  -ms-transition: ease 0.15s;
  -o-transition: ease 0.15s; }

.screen-dark.active {
  z-index: 9998;
  background: rgba(0, 0, 0, 0.6); }

main {
  overflow-x: hidden;
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 30px; }

main:after {
  content: "";
  background: url(../img/background.png);
  background-size: 160px;
  opacity: 0.8;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1; }

.ts-filter-gray {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%); }

.ui-helper-hidden-accessible {
  display: none !important; }

.goToTop {
  background: #2c2b2d;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  font-size: 20px; }

.ui-tooltip {
  display: inline;
  border: 0px solid gainsboro;
  color: white;
  background: #2c2b2d;
  border-radius: 0px;
  box-shadow: none;
  font-size: 0.9em; }

.ts-mc-1--bc {
  background: #f7556d; }

b.ts-mc-1--bc,
label.ts-mc-1--bc {
  background: transparent;
  color: #f52443; }

.ts-mc-2--bc {
  background: #f79a5e; }

b.ts-mc-2--bc,
label.ts-mc-2--bc {
  background: transparent;
  color: #f57c2d; }

.ts-mc-3--bc {
  background: #aec742; }

b.ts-mc-3--bc,
label.ts-mc-3--bc {
  background: transparent;
  color: #8fa531; }

.ts-mc-4--bc {
  background: #35afd0; }

b.ts-mc-4--bc,
label.ts-mc-4--bc {
  background: transparent;
  color: #288faa; }

.ts-mc-5--bc {
  background: #7a9fef; }

b.ts-mc-5--bc,
label.ts-mc-5--bc {
  background: transparent;
  color: #4c7eea; }

.ts-mc-6--bc {
  background: #d77ebb; }

b.ts-mc-6--bc,
label.ts-mc-6--bc {
  background: transparent;
  color: #cb57a6; }

.ts-mc-7--bc {
  background: #fe7a8d; }

b.ts-mc-7--bc,
label.ts-mc-7--bc {
  background: transparent;
  color: #fe4762; }

.ts-mc-8--bc {
  background: #bbb9c1; }

b.ts-mc-8--bc,
label.ts-mc-8--bc {
  background: transparent;
  color: #a19ea9; }

header {
  z-index: 10000;
  display: block;
  width: 100%;
  background: #fff;
  position: fixed; }

#header-top--strip {
  display: block;
  height: 90px;
  margin-top: 20px;
  -webkit-transition: ease 0.25s;
  -moz-transition: ease 0.25s;
  -ms-transition: ease 0.25s;
  -o-transition: ease 0.25s;
  transition: ease 0.25s; }
  #header-top--strip #strip-logo {
    float: right;
    width: 300px; }
  #header-top--strip #strip-gotrans {
    float: left;
    padding: 15px 0;
    width: 105px;
    overflow: hidden;
    font-size: .9em;
    text-align: right; }

#header-top--menu {
  height: 45px;
  -webkit-transition: ease 0.25s;
  -moz-transition: ease 0.25s;
  -ms-transition: ease 0.25s;
  -o-transition: ease 0.25s;
  transition: ease 0.25s; }
  #header-top--menu li {
    position: relative;
    width: 10.61%;
    margin: 0 0.25%;
    text-align: center; }
    #header-top--menu li .menu-button {
      display: block;
      position: relative;
      width: 100%;
      height: 35px;
      font-weight: 700; }
    #header-top--menu li .menu-button:after {
      content: '';
      z-index: -1;
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 6px;
      margin-top: 4px;
      -webkit-transform: skew(-30deg, 0deg);
      -moz-transform: skew(-30deg, 0deg);
      -ms-transform: skew(-30deg, 0deg);
      -o-transform: skew(-30deg, 0deg);
      transform: skew(-30deg, 0deg); }
    #header-top--menu li a.ts-mc-1:hover, #header-top--menu li a.ts-mc-1:focus {
      color: #dc0a29; }
    #header-top--menu li a.ts-mc-2:hover, #header-top--menu li a.ts-mc-2:focus {
      color: #e4600b; }
    #header-top--menu li a.ts-mc-3:hover, #header-top--menu li a.ts-mc-3:focus {
      color: #6d7e25; }
    #header-top--menu li a.ts-mc-4:hover, #header-top--menu li a.ts-mc-4:focus {
      color: #1e6c81; }
    #header-top--menu li a.ts-mc-5:hover, #header-top--menu li a.ts-mc-5:focus {
      color: #1f5de4; }
    #header-top--menu li a.ts-mc-6:hover, #header-top--menu li a.ts-mc-6:focus {
      color: #b6398f; }
    #header-top--menu li a.ts-mc-7:hover, #header-top--menu li a.ts-mc-7:focus {
      color: #fd1536; }
    #header-top--menu li a.ts-mc-8:hover, #header-top--menu li a.ts-mc-8:focus {
      color: #868391; }
    #header-top--menu li a.ts-mc-1:after {
      background-color: #f7556d; }
    #header-top--menu li a.ts-mc-2:after {
      background-color: #f79a5e; }
    #header-top--menu li a.ts-mc-3:after {
      background-color: #aec742; }
    #header-top--menu li a.ts-mc-4:after {
      background-color: #35afd0; }
    #header-top--menu li a.ts-mc-5:after {
      background-color: #7a9fef; }
    #header-top--menu li a.ts-mc-6:after {
      background-color: #d77ebb; }
    #header-top--menu li a.ts-mc-7:after {
      background-color: #fe7a8d; }
    #header-top--menu li a.ts-mc-8:after {
      background-color: #bbb9c1; }
    #header-top--menu li .menu-sub--title.ts-mc-1 {
      color: #f7556d; }
    #header-top--menu li .menu-sub--title.ts-mc-2 {
      color: #f79a5e; }
    #header-top--menu li .menu-sub--title.ts-mc-3 {
      color: #aec742; }
    #header-top--menu li .menu-sub--title.ts-mc-4 {
      color: #35afd0; }
    #header-top--menu li .menu-sub--title.ts-mc-5 {
      color: #7a9fef; }
    #header-top--menu li .menu-sub--title.ts-mc-6 {
      color: #d77ebb; }
    #header-top--menu li .menu-sub--title.ts-mc-7 {
      color: #fe7a8d; }
    #header-top--menu li .menu-sub--title.ts-mc-8 {
      color: #bbb9c1; }
    #header-top--menu li .menu-sub {
      visibility: hidden;
      opacity: 0;
      z-index: 9;
      width: 300%;
      position: absolute;
      top: 100%;
      right: 0;
      padding: 10%;
      background: #fff;
      border-bottom-width: 4px !important;
      border-bottom-style: solid !important;
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
      -webkit-transition: ease 0.25s;
      -moz-transition: ease 0.25s;
      -ms-transition: ease 0.25s;
      -o-transition: ease 0.25s;
      transition: ease 0.25s; }
      #header-top--menu li .menu-sub .menu-sub--title {
        font-size: 1.2em;
        text-align: right;
        padding-bottom: 3px;
        margin-bottom: 8px;
        border-bottom: 1px solid #ebe3e1; }
      #header-top--menu li .menu-sub li {
        width: 48%;
        padding: 0 2% 0 0;
        height: 30px;
        line-height: 30px;
        margin: 0;
        text-align: right; }
        #header-top--menu li .menu-sub li a {
          display: block;
          width: 100%;
          font-weight: 400; }
        #header-top--menu li .menu-sub li :hover, #header-top--menu li .menu-sub li :focus {
          text-decoration: underline; }
        #header-top--menu li .menu-sub li :before {
          content: '\f104';
          color: #5c5c5c;
          position: relative;
          left: 4%;
          -webkit-transition: ease 0.15s;
          -moz-transition: ease 0.15s;
          -ms-transition: ease 0.15s;
          -o-transition: ease 0.15s;
          transition: ease 0.15s; }
        #header-top--menu li .menu-sub li :hover:before, #header-top--menu li .menu-sub li :focus:before {
          left: 2%; }
  #header-top--menu li:hover .menu-sub, #header-top--menu li:focus .menu-sub, #header-top--menu li.active .menu-sub {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s; }

#header-top--nav {
  opacity: 1;
  display: block;
  height: 35px;
  line-height: 35px;
  font-size: 0.9em;
  background: #fbfbfb;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7; }
  #header-top--nav a:hover, #header-top--nav a:focus {
    text-decoration: underline; }

#header-top--nav.active {
  height: 0;
  opacity: 0;
  overflow: hidden; }

#ts-side--con {
  z-index: 9999;
  overflow: hidden;
  position: fixed;
  width: 400px;
  top: 250px;
  left: 0;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1); }
  #ts-side--con h3 {
    font-weight: normal; }
  #ts-side--con #side--title {
    font-size: 0.95em;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #cccccc; }
  #ts-side--con #side--title.icon-nav:before {
    float: right;
    width: 45px;
    height: 45px;
    line-height: 48px;
    margin-left: 3%;
    text-align: center;
    font-size: 1.8em;
    color: #e6e6e6;
    background: #333333; }
  #ts-side--con #side--nav {
    float: right;
    width: 95%;
    padding: 2.5%; }
    #ts-side--con #side--nav li {
      display: inline-block;
      width: 33.333%;
      text-align: center; }
      #ts-side--con #side--nav li .side--nav-item {
        padding: 10% 5%;
        color: #333333;
        -webkit-transition: ease 0.1s;
        -moz-transition: ease 0.1s;
        -ms-transition: ease 0.1s;
        -o-transition: ease 0.1s;
        transition: ease 0.1s; }
        #ts-side--con #side--nav li .side--nav-item .item--icon {
          display: block;
          font-size: 3.5em;
          height: 60px;
          -webkit-transition: ease 0.15s;
          -moz-transition: ease 0.15s;
          -ms-transition: ease 0.15s;
          -o-transition: ease 0.15s;
          transition: ease 0.15s; }
        #ts-side--con #side--nav li .side--nav-item .item--content {
          display: block;
          font-size: 0.9em; }
      #ts-side--con #side--nav li a:hover > .side--nav-item, #ts-side--con #side--nav li a:focus > .side--nav-item {
        background: #f3f3f3; }
        #ts-side--con #side--nav li a:hover > .side--nav-item .item--icon, #ts-side--con #side--nav li a:focus > .side--nav-item .item--icon {
          font-size: 3.8em; }
  #ts-side--con #side--buttons {
    display: inline-block;
    width: 90%;
    margin: 0 0 2.5% 0;
    padding: 5%;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc; }
    #ts-side--con #side--buttons a {
      display: inline-block;
      width: 48%;
      height: 42px;
      line-height: 42px;
      text-align: center;
      color: #fff;
      -webkit-transition: ease 0.15s;
      -moz-transition: ease 0.15s;
      -ms-transition: ease 0.15s;
      -o-transition: ease 0.15s;
      transition: ease 0.15s; }
    #ts-side--con #side--buttons :first-child {
      background: #f7556d;
      margin-left: 2.5%; }
    #ts-side--con #side--buttons :first-child:hover, #ts-side--con #side--buttons :first-child:focus {
      background: #f52443; }
    #ts-side--con #side--buttons :last-child {
      background: #aec742; }
    #ts-side--con #side--buttons :last-child:hover, #ts-side--con #side--buttons :last-child:focus {
      background: #8fa531; }
  #ts-side--con #side--news {
    display: block;
    padding: 0 5% 2.5%;
    border-bottom: 1px solid #cccccc; }
    #ts-side--con #side--news ul {
      display: inline-block; }
      #ts-side--con #side--news ul li {
        width: 100%;
        line-height: 25px;
        font-size: 0.95em; }
        #ts-side--con #side--news ul li a:hover, #ts-side--con #side--news ul li a:focus {
          text-decoration: underline; }
      #ts-side--con #side--news ul li:before {
        content: "\2022";
        position: relative;
        top: 1px;
        margin-left: 1%;
        font-size: 1.4em;
        color: #4a4a4a; }
  #ts-side--con #side--search {
    padding: 7.5% 5%;
    height: 35px; }
    #ts-side--con #side--search form {
      position: relative; }
      #ts-side--con #side--search form input[type="text"] {
        position: absolute;
        top: 0;
        right: 0;
        width: 75%;
        height: 35px;
        padding: 0 5% 0 20%;
        line-height: 35px;
        border-radius: 4px;
        font-family: 'Alef', sans-serif;
        font-size: 1em;
        color: #333333;
        border: 1px solid #cccccc;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1); }
      #ts-side--con #side--search form #search--type-submit {
        position: absolute;
        top: 1px;
        left: -1px;
        width: 20%;
        height: 35px;
        line-height: 35px;
        color: #e6e6e6;
        background: #333333;
        border-radius: 4px 0 0 4px; }
        #ts-side--con #side--search form #search--type-submit input[type="submit"] {
          z-index: 1;
          position: absolute;
          left: 0;
          width: 100%;
          padding-left: 15%;
          height: 35px;
          color: #e6e6e6;
          font-size: 1.05em;
          text-align: left;
          font-family: 'Alef', sans-serif;
          -webkit-transition: ease 0.15s;
          -moz-transition: ease 0.15s;
          -ms-transition: ease 0.15s;
          -o-transition: ease 0.15s;
          transition: ease 0.15s; }
      #ts-side--con #side--search form #search--type-submit:before {
        content: "\f002";
        z-index: 0;
        position: absolute;
        width: 45%;
        padding-left: 55%;
        text-align: center;
        -webkit-transition: ease 0.15s;
        -moz-transition: ease 0.15s;
        -ms-transition: ease 0.15s;
        -o-transition: ease 0.15s;
        transition: ease 0.15s; }
      #ts-side--con #side--search form #search--type-submit:hover > input[type="submit"],
      #ts-side--con #side--search form #search--type-submit:focus > input[type="submit"] {
        padding-left: 25%;
        color: transparent; }
      #ts-side--con #side--search form #search--type-submit:hover:before,
      #ts-side--con #side--search form #search--type-submit:focus:before {
        width: 100%;
        padding-left: 0%;
        font-size: 1.3em; }
      #ts-side--con #side--search form label {
        z-index: 9;
        position: absolute;
        right: 0;
        top: 0;
        background: rgba(255, 255, 255, 0);
        color: #7d7d7d;
        line-height: 10px;
        margin-right: 5%; }

#ts-menu-shortcuts-but, #ts-menu-top-but {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #333333;
  border: 2px solid #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5); }

#ts-menu-shortcuts-but:before, #ts-menu-top-but:before {
  content: '\e00c';
  font-family: 'FontAwesome';
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  font-size: 20px; }

#ts-menu-shortcuts-but.active:before, #ts-menu-top-but.active:before {
  content: '\f00d';
  font-size: 25px; }

#ts-menu-top-but {
  left: 0;
  right: 20px; }

#ts-menu-shortcuts-but:before {
  content: '\f124'; }

#ts-menu-top-but:before {
  content: '\f0c9'; }

.ts-headline--strip {
  position: relative;
  display: inline-block;
  height: 500px;
  width: 100%;
  margin-bottom: 30px; }

#search--type-free.ts-input {
  margin: 0; }

.swiper-container {
  overflow: hidden;
  float: right;
  position: relative;
  top: 50px;
  width: 70%;
  margin-top: 10px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1; }

.swiper-img {
  width: 100%;
  display: block;
  max-height: 330px; }

.swiper-content {
  display: block;
  height: 95px;
  color: #333333;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25); }

.swiper-content .swiper-content--headline {
  display: inline-block;
  margin-top: 5px;
  margin-right: -45px;
  font-weight: normal;
  font-size: 1.55em; }

.swiper-content p {
  display: block;
  width: auto;
  padding: 0 45px 0 60%;
  font-size: 15px;
  line-height: 18px; }

.swiper-content .swiper-content--date {
  position: relative;
  top: 33px;
  right: -33px;
  float: right;
  width: 86px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  text-align: left;
  font-size: 14pt;
  color: #fff;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg); }

.swiper-content .swiper-content--link {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -1px;
  width: 150px;
  padding: 63px 15px 10px 15px;
  text-align: left;
  background: transparent; }

.swiper-content .swiper-content--link a {
  display: inline-block;
  width: auto;
  padding-left: 20px;
  color: #fff; }

.swiper-content .swiper-content--link a:hover {
  text-decoration: underline; }

.swiper-content .swiper-content--link:after {
  content: "\f104";
  position: absolute;
  bottom: 11px;
  left: 20px;
  font-size: 20px;
  color: #fff; }

.swiper-content .swiper-content--link:before {
  content: '';
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: -1px;
  border-right: 90px solid transparent;
  border-top: 90px solid transparent; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-slide.swiper-slide-active {
  z-index: 1; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  z-index: -1;
  overflow: hidden; }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  z-index: 1;
  position: absolute;
  top: 100px;
  background: #333333;
  color: white;
  width: 50px;
  height: 100px;
  cursor: pointer;
  line-height: 100px; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-container-rtl .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  -webkit-transition: ease 0.15s;
  -moz-transition: ease 0.15s;
  -ms-transition: ease 0.15s;
  -o-transition: ease 0.15s;
  transition: ease 0.15s; }

.swiper-container-rtl .swiper-button-next {
  font-size: 3em;
  right: 0px;
  left: auto;
  text-align: center;
  border-radius: 5px 0 0 5px; }

.swiper-container-rtl .swiper-button-next:hover {
  padding-right: 15px; }

.swiper-container-rtl .swiper-button-prev {
  font-size: 3em;
  left: -1px;
  right: auto;
  text-align: center;
  border-radius: 0px 5px 5px 0px; }

.swiper-container-rtl .swiper-button-prev:hover {
  padding-left: 15px; }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }

.swiper-container-vertical > .swiper-pagination .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination {
  bottom: 0px;
  background-color: gainsboro;
  height: 2vh;
  padding: 5vh 0vh 5vh 0vh;
  left: 0;
  width: 100%; }

.swiper-container-horizontal > .swiper-pagination .swiper-pagination-bullet {
  margin: 0 5px; }

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, transparent, transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, transparent, transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, transparent, transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, transparent, transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(transparent), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, transparent, transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, transparent, transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, transparent, transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, transparent, transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, transparent, transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, transparent, transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, transparent, transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, transparent, transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(transparent), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, transparent, transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, transparent, transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, transparent, transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, transparent, transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

/* Cube */
.swiper-container-cube {
  overflow: visible; }

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  z-index: 1; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: transparent; }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: transparent;
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

.swiper-container.ts-gallery {
  width: 38%;
  height: 279px;
  top: 0;
  margin-top: 0;
  padding: 10px 0;
  box-shadow: none;
  border: 1px solid #cccccc; }

.swiper-container.ts-gallery .swiper-img {
  height: 279px; }

.swiper-container.ts-gallery .swiper-button-next,
.swiper-container.ts-gallery .swiper-button-prev {
  width: 35px; }

.swiper-content--date.ts-mc-1--bc {
  background: #f7556d; }

.swiper-content--link.ts-mc-1--bc:before {
  border-left: 90px solid #f7556d;
  border-bottom: 90px solid #f7556d; }

.swiper-content--date.ts-mc-2--bc {
  background: #f79a5e; }

.swiper-content--link.ts-mc-2--bc:before {
  border-left: 90px solid #f79a5e;
  border-bottom: 90px solid #f79a5e; }

.swiper-content--date.ts-mc-3--bc {
  background: #aec742; }

.swiper-content--link.ts-mc-3--bc:before {
  border-left: 90px solid #aec742;
  border-bottom: 90px solid #aec742; }

.swiper-content--date.ts-mc-4--bc {
  background: #35afd0; }

.swiper-content--link.ts-mc-4--bc:before {
  border-left: 90px solid #35afd0;
  border-bottom: 90px solid #35afd0; }

.swiper-content--date.ts-mc-5--bc {
  background: #7a9fef; }

.swiper-content--link.ts-mc-5--bc:before {
  border-left: 90px solid #7a9fef;
  border-bottom: 90px solid #7a9fef; }

.swiper-content--date.ts-mc-6--bc {
  background: #d77ebb; }

.swiper-content--link.ts-mc-6--bc:before {
  border-left: 90px solid #d77ebb;
  border-bottom: 90px solid #d77ebb; }

.swiper-content--date.ts-mc-7--bc {
  background: #fe7a8d; }

.swiper-content--link.ts-mc-7--bc:before {
  border-left: 90px solid #fe7a8d;
  border-bottom: 90px solid #fe7a8d; }

.swiper-content--date.ts-mc-8--bc {
  background: #bbb9c1; }

.swiper-content--link.ts-mc-8--bc:before {
  border-left: 90px solid #bbb9c1;
  border-bottom: 90px solid #bbb9c1; }

footer {
  height: 250px; }

.footer-nav {
  display: flex;
  align-self: center;
  height: 100%;
  padding: 0 5%; }
  .footer-nav ul {
    display: flex;
    align-self: center;
    height: 35%; }
    .footer-nav ul li {
      position: relative;
      height: 80%;
      padding: 5%;
      margin: 0 10px;
      background: #fff;
      border-radius: 5px;
      overflow: hidden; }
      .footer-nav ul li img {
        height: 100%;
        width: auto;
        display: block; }

.ts-input {
  z-index: 1;
  position: relative;
  display: block;
  width: 45%;
  height: auto;
  float: right;
  margin: 5px 2.5%;
  background: transparent; }
  .ts-input.input-select:after {
    z-index: -1;
    font-family: 'FontAwesome';
    content: "\f107";
    position: absolute;
    top: 22px;
    left: 0;
    font-size: 20px;
    width: 35px;
    height: 35px;
    border-radius: 5px 0 0 5px;
    color: #fff;
    text-align: center;
    line-height: 35px;
    background: #cccccc; }
  .ts-input.input-select.ts-mc-1--bc:after {
    background: #f52443; }
  .ts-input.input-select.ts-mc-2--bc:after {
    background: #f57c2d; }
  .ts-input.input-select.ts-mc-3--bc:after {
    background: #8fa531; }
  .ts-input.input-select.ts-mc-4--bc:after {
    background: #288faa; }
  .ts-input.input-select.ts-mc-5--bc:after {
    background: #4c7eea; }
  .ts-input.input-select.ts-mc-6--bc:after {
    background: #cb57a6; }
  .ts-input.input-select.ts-mc-7--bc:after {
    background: #fe4762; }
  .ts-input.input-select.ts-mc-8--bc:after {
    background: #a19ea9; }
  .ts-input.input-long {
    width: 95%; }
  .ts-input.input-submit {
    float: left;
    text-align: left; }
    .ts-input.input-submit > input {
      cursor: pointer;
      width: 47.5%;
      height: 35px;
      border-radius: 5px;
      color: #fff;
      background: #afafaf;
      -webkit-transition: ease 0.2s;
      -moz-transition: ease 0.2s;
      -ms-transition: ease 0.2s;
      -o-transition: ease 0.2s;
      transition: ease 0.2s; }
      .ts-input.input-submit > input:hover {
        background: #9b9b9b; }
      .ts-input.input-submit > input:first-child {
        margin-left: 2.5%; }
    .ts-input.input-submit.ts-mc-1--bc > input[type="submit"] {
      background: #f52443; }
      .ts-input.input-submit.ts-mc-1--bc > input[type="submit"]:hover {
        background: #dc0a29; }
    .ts-input.input-submit.ts-mc-2--bc > input[type="submit"] {
      background: #f57c2d; }
      .ts-input.input-submit.ts-mc-2--bc > input[type="submit"]:hover {
        background: #e4600b; }
    .ts-input.input-submit.ts-mc-3--bc > input[type="submit"] {
      background: #8fa531; }
      .ts-input.input-submit.ts-mc-3--bc > input[type="submit"]:hover {
        background: #6d7e25; }
    .ts-input.input-submit.ts-mc-4--bc > input[type="submit"] {
      background: #288faa; }
      .ts-input.input-submit.ts-mc-4--bc > input[type="submit"]:hover {
        background: #1e6c81; }
    .ts-input.input-submit.ts-mc-5--bc > input[type="submit"] {
      background: #4c7eea; }
      .ts-input.input-submit.ts-mc-5--bc > input[type="submit"]:hover {
        background: #1f5de4; }
    .ts-input.input-submit.ts-mc-6--bc > input[type="submit"] {
      background: #cb57a6; }
      .ts-input.input-submit.ts-mc-6--bc > input[type="submit"]:hover {
        background: #b6398f; }
    .ts-input.input-submit.ts-mc-7--bc > input[type="submit"] {
      background: #fe4762; }
      .ts-input.input-submit.ts-mc-7--bc > input[type="submit"]:hover {
        background: #fd1536; }
    .ts-input.input-submit.ts-mc-8--bc > input[type="submit"] {
      background: #a19ea9; }
      .ts-input.input-submit.ts-mc-8--bc > input[type="submit"]:hover {
        background: #868391; }
  .ts-input > input[type="text"], .ts-input textarea, .ts-input select,
  .ts-input input[type="email"], .ts-input input[type="tel"] {
    width: 90%;
    height: 35px;
    padding: 0 5%;
    border: 1px solid #cccccc;
    border-radius: 5px; }
  .ts-input select {
    width: 100%; }
  .ts-input textarea {
    height: 100px;
    padding: 20px 5%; }
  .ts-input.ts-mc-1--bc > input:focus,
  .ts-input.ts-mc-1--bc > textarea:focus,
  .ts-input.ts-mc-1--bc > select:focus {
    border-color: #f52443; }
  .ts-input.ts-mc-2--bc > input:focus,
  .ts-input.ts-mc-2--bc > textarea:focus,
  .ts-input.ts-mc-2--bc > select:focus {
    border-color: #f57c2d; }
  .ts-input.ts-mc-3--bc > input:focus,
  .ts-input.ts-mc-3--bc > textarea:focus,
  .ts-input.ts-mc-3--bc > select:focus {
    border-color: #8fa531; }
  .ts-input.ts-mc-4--bc > input:focus,
  .ts-input.ts-mc-4--bc > textarea:focus,
  .ts-input.ts-mc-4--bc > select:focus {
    border-color: #288faa; }
  .ts-input.ts-mc-5--bc > input:focus,
  .ts-input.ts-mc-5--bc > textarea:focus,
  .ts-input.ts-mc-5--bc > select:focus {
    border-color: #4c7eea; }
  .ts-input.ts-mc-6--bc > input:focus,
  .ts-input.ts-mc-6--bc > textarea:focus,
  .ts-input.ts-mc-6--bc > select:focus {
    border-color: #cb57a6; }
  .ts-input.ts-mc-7--bc > input:focus,
  .ts-input.ts-mc-7--bc > textarea:focus,
  .ts-input.ts-mc-7--bc > select:focus {
    border-color: #fe4762; }
  .ts-input.ts-mc-8--bc > input:focus,
  .ts-input.ts-mc-8--bc > textarea:focus,
  .ts-input.ts-mc-8--bc > select:focus {
    border-color: #a19ea9; }
  .ts-input.input-checkbox > label {
    position: relative;
    top: 0%;
    right: 0%;
    margin-right: 10px;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  .ts-input.input-checkbox > input {
    float: right;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #cccccc; }
  .ts-input.input-checkbox > input:checked {
    background: #cccccc; }
  .ts-input.input-checkbox.ts-mc-1--bc > input:checked {
    border-color: #f52443;
    background: #f52443; }
  .ts-input.input-checkbox.ts-mc-2--bc > input:checked {
    border-color: #f57c2d;
    background: #f57c2d; }
  .ts-input.input-checkbox.ts-mc-3--bc > input:checked {
    border-color: #8fa531;
    background: #8fa531; }
  .ts-input.input-checkbox.ts-mc-4--bc > input:checked {
    border-color: #288faa;
    background: #288faa; }
  .ts-input.input-checkbox.ts-mc-5--bc > input:checked {
    border-color: #4c7eea;
    background: #4c7eea; }
  .ts-input.input-checkbox.ts-mc-6--bc > input:checked {
    border-color: #cb57a6;
    background: #cb57a6; }
  .ts-input.input-checkbox.ts-mc-7--bc > input:checked {
    border-color: #fe4762;
    background: #fe4762; }
  .ts-input.input-checkbox.ts-mc-8--bc > input:checked {
    border-color: #a19ea9;
    background: #a19ea9; }

.ts-share {
  float: right;
  width: 100%;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #cccccc; }
  .ts-share > * {
    display: inline-block;
    vertical-align: middle; }
  .ts-share .ts-share--buttons {
    font-size: 18px;
    background: transparent; }
    .ts-share .ts-share--buttons ul {
      display: block;
      width: 100%; }
      .ts-share .ts-share--buttons ul li {
        margin-left: 10px; }
    .ts-share .ts-share--buttons.ts-mc-1--bc a i {
      color: #c30925; }
    .ts-share .ts-share--buttons.ts-mc-2--bc a i {
      color: #cb560a; }
    .ts-share .ts-share--buttons.ts-mc-3--bc a i {
      color: #5c6a1f; }
    .ts-share .ts-share--buttons.ts-mc-4--bc a i {
      color: #195b6c; }
    .ts-share .ts-share--buttons.ts-mc-5--bc a i {
      color: #1953d0; }
    .ts-share .ts-share--buttons.ts-mc-6--bc a i {
      color: #a33380; }
    .ts-share .ts-share--buttons.ts-mc-7--bc a i {
      color: #f70225; }
    .ts-share .ts-share--buttons.ts-mc-8--bc a i {
      color: #797685; }
  .ts-share .ts-share--facebook {
    float: left; }

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */ }

.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0; }

.noUi-origin {
  position: absolute;
  height: 0;
  width: 0; }

.noUi-handle {
  position: relative;
  z-index: 1; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 10px; }

.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  left: -10px;
  top: -6px; }

.noUi-vertical {
  width: 20px; }

.noUi-vertical .noUi-handle {
  width: 20px;
  height: 20px;
  left: -6px;
  top: -17px; }

/* Styling;
 */
.noUi-target {
  background: #fff;
  border-radius: 10px; }

.noUi-connect {
  background: #333333;
  -webkit-transition: background 450ms;
  transition: background 450ms; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize; }

.noUi-vertical .noUi-draggable {
  cursor: n-resize; }

.noUi-handle {
  background: #333333;
  border: 3px solid #fff;
  border-radius: 20px; }

/* Handle stripes;
 */
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Tooltips
 */
.noUi-tooltip {
  display: block;
  position: absolute;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px 10px;
  text-align: center;
  font-weight: bold; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 200%; }

.noUi-horizontal .noUi-tooltip:after {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 200%; }

/* Pips

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #fff; }

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  text-align: center; }

.noUi-value-sub {
  color: #fff;
  font-size: 10px; }

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: transparent; }

.noUi-marker-sub {
  background: #fff; }

.noUi-marker-large {
  background: #fff; }

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate3d(-50%, 50%, 0);
  transform: translate3d(-50%, 50%, 0); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  padding-left: 25px; }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

#ts-main--events {
  z-index: -1; }

.ts-main--title {
  z-index: 1;
  display: inline-block;
  position: relative;
  padding: 4px 55px 4px 25px;
  color: #fff;
  background: transparent; }
  .ts-main--title span {
    position: absolute;
    top: 0;
    right: 0px;
    width: 40px;
    height: 100%;
    line-height: 35px;
    text-align: center;
    border-radius: 0 5px 0 0;
    background: #333333; }

.ts-main--title:after {
  content: '';
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 6px 50px 0 0;
  -webkit-transform: skew(-35deg, 0deg);
  -moz-transform: skew(-35deg, 0deg);
  -ms-transform: skew(-35deg, 0deg);
  -o-transform: skew(-35deg, 0deg);
  transform: skew(-35deg, 0deg); }

.ts-main--title.ts-mc-1--bc:after {
  background-color: #f7556d; }

.ts-main--title.ts-mc-2--bc:after {
  background-color: #f79a5e; }

.ts-main--title.ts-mc-3--bc:after {
  background-color: #aec742; }

.ts-main--title.ts-mc-4--bc:after {
  background-color: #35afd0; }

.ts-main--title.ts-mc-5--bc:after {
  background-color: #7a9fef; }

.ts-main--title.ts-mc-6--bc:after {
  background-color: #d77ebb; }

.ts-main--title.ts-mc-7--bc:after {
  background-color: #fe7a8d; }

.ts-main--title.ts-mc-8--bc:after {
  background-color: #bbb9c1; }

.events--item-con {
  display: inline-block;
  position: relative;
  width: 105%;
  right: -2.5%;
  margin-bottom: 60px; }
  .events--item-con .events--item-box {
    display: inline-block;
    float: right;
    width: 25%; }
    .events--item-con .events--item-box .events--item {
      position: relative;
      display: inline-block;
      width: 80%;
      height: auto;
      padding: 10%; }
      .events--item-con .events--item-box .events--item .events-item-box--title {
        margin-top: 10px;
        line-height: 25px;
        min-height: 50px;
        font-size: 1.25em;
        color: #333333; }
      .events--item-con .events--item-box .events--item .events-item-box--con {
        z-index: 1;
        position: relative;
        overflow: hidden;
        height: 300px;
        border-radius: 10px; }
        .events--item-con .events--item-box .events--item .events-item-box--con img {
          z-index: -1;
          position: absolute;
          top: 0;
          left: 50%;
          height: 100%;
          width: auto;
          transform: translate(-50%);
          -webkit-transform: translate(-50%);
          -moz-transform: translate(-50%);
          -ms-transform: translate(-50%);
          -o-transform: translate(-50%); }
        .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content.active {
          top: 10px; }
        .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content {
          position: absolute;
          top: 250px;
          right: 0;
          width: 100%;
          height: 100%;
          color: #fff;
          background: rgba(0, 0, 0, 0.8);
          font-size: 13pt;
          -webkit-transition: ease 0.4s;
          -moz-transition: ease 0.4s;
          -ms-transition: ease 0.4s;
          -o-transition: ease 0.4s;
          transition: ease 0.4s; }
          .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div {
            height: auto;
            line-height: 40px;
            padding: 0 0 0 5%;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
            .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div > .fa {
              float: right;
              width: 45px;
              height: 40px;
              margin-left: 2.5%;
              line-height: 40px;
              text-align: center;
              font-size: 1.2em; }
            .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div > .fa.fa-map-marker {
              height: 65px;
              line-height: 20px; }
            .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div > .fa.fa-ils {
              display: none; }
            .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div span a {
              color: #fff; }
            .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div span a:after {
              content: '\f104';
              margin-right: 10px;
              -webkit-transition: ease 0.15s;
              -moz-transition: ease 0.15s;
              -ms-transition: ease 0.15s;
              -o-transition: ease 0.15s;
              transition: ease 0.15s; }
            .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div span a:hover:after {
              margin-right: 15px; }
            .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div .events-item-box--trigger {
              color: #fff; }
            .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div .events-item-box--trigger.active:before {
              content: '\f00d'; }
          .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div:nth-child(3) {
            line-height: 20px;
            padding: 15px 0 15px 5%; }
          .events--item-con .events--item-box .events--item .events-item-box--con .events-item-box--content > div:last-child {
            border-bottom: 0px solid rgba(255, 255, 255, 0.5); }
      .events--item-con .events--item-box .events--item .events-item-box--link {
        display: inline-block;
        width: 89.5%;
        height: 35px;
        line-height: 35px;
        padding: 0 5%;
        margin-top: 10px;
        border-radius: 5px;
        background: transparent;
        -webkit-transition: ease 0.15s;
        -moz-transition: ease 0.15s;
        -ms-transition: ease 0.15s;
        -o-transition: ease 0.15s;
        transition: ease 0.15s; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-1--bc {
        color: #f52443;
        border: 1px solid #f7556d;
        box-shadow: 0px 0px 3px 0px #fcb6c1; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-1--bc:hover {
        box-shadow: 0px 0px 5px 0px #f7556d; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-2--bc {
        color: #f57c2d;
        border: 1px solid #f79a5e;
        box-shadow: 0px 0px 3px 0px #fcd7bf; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-2--bc:hover {
        box-shadow: 0px 0px 5px 0px #f79a5e; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-3--bc {
        color: #8fa531;
        border: 1px solid #aec742;
        box-shadow: 0px 0px 3px 0px #d0de91; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-3--bc:hover {
        box-shadow: 0px 0px 5px 0px #aec742; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-4--bc {
        color: #288faa;
        border: 1px solid #35afd0;
        box-shadow: 0px 0px 3px 0px #88d0e3; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-4--bc:hover {
        box-shadow: 0px 0px 5px 0px #35afd0; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-5--bc {
        color: #4c7eea;
        border: 1px solid #7a9fef;
        box-shadow: 0px 0px 3px 0px #d5e1fa; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-5--bc:hover {
        box-shadow: 0px 0px 5px 0px #7a9fef; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-6--bc {
        color: #cb57a6;
        border: 1px solid #d77ebb;
        box-shadow: 0px 0px 3px 0px #efcce4; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-6--bc:hover {
        box-shadow: 0px 0px 5px 0px #d77ebb; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-7--bc {
        color: #fe4762;
        border: 1px solid #fe7a8d;
        box-shadow: 0px 0px 3px 0px #ffdfe4; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-7--bc:hover {
        box-shadow: 0px 0px 5px 0px #fe7a8d; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-8--bc {
        color: #a19ea9;
        border: 1px solid #bbb9c1;
        box-shadow: 0px 0px 3px 0px #f0eff1; }
      .events--item-con .events--item-box .events--item .events-item-box--link.ts-mc-8--bc:hover {
        box-shadow: 0px 0px 5px 0px #bbb9c1; }
      .events--item-con .events--item-box .events--item .events-item-box--link:after {
        content: '\f104';
        font-size: 1.3em;
        margin-left: 10px;
        float: left;
        -webkit-transition: ease 0.15s;
        -moz-transition: ease 0.15s;
        -ms-transition: ease 0.15s;
        -o-transition: ease 0.15s;
        transition: ease 0.15s; }
      .events--item-con .events--item-box .events--item .events-item-box--link:hover:after {
        margin-left: 0px; }
      .events--item-con .events--item-box .events--item .events-item-box--con:before,
      .events--item-con .events--item-box .events--item .events-item-box--con:after {
        content: '';
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        height: 10px; }
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-1--bc:before,
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-1--bc:after {
        background-color: #f7556d; }
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-2--bc:before,
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-2--bc:after {
        background-color: #f79a5e; }
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-3--bc:before,
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-3--bc:after {
        background-color: #aec742; }
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-4--bc:before,
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-4--bc:after {
        background-color: #35afd0; }
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-5--bc:before,
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-5--bc:after {
        background-color: #7a9fef; }
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-6--bc:before,
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-6--bc:after {
        background-color: #d77ebb; }
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-7--bc:before,
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-7--bc:after {
        background-color: #fe7a8d; }
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-8--bc:before,
      .events--item-con .events--item-box .events--item .events-item-box--con.ts-mc-8--bc:after {
        background-color: #bbb9c1; }
      .events--item-con .events--item-box .events--item .events-item-box--con:after {
        position: absolute;
        bottom: 0;
        right: 0; }

#ts-main--classes {
  position: relative;
  top: -42.5px; }
  #ts-main--classes #classes--search-panel {
    display: inline-block;
    width: 100%;
    height: auto;
    padding: 2.5% 0;
    background: transparent; }
    #ts-main--classes #classes--search-panel #search-panel--nav a {
      overflow: hidden;
      z-index: 1;
      position: relative;
      float: right;
      width: 25%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-weight: 700;
      color: #fff; }
    #ts-main--classes #classes--search-panel #search-panel--nav a:before {
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 90%;
      height: 100%;
      margin: 0 5%;
      border-radius: 5px 8px 0 0;
      background: #333333;
      -webkit-transform: skew(-30deg, 0deg);
      -moz-transform: skew(-30deg, 0deg);
      -ms-transform: skew(-30deg, 0deg);
      -o-transform: skew(-30deg, 0deg);
      transform: skew(-30deg, 0deg);
      -webkit-transition: ease 0.15s;
      -moz-transition: ease 0.15s;
      -ms-transition: ease 0.15s;
      -o-transition: ease 0.15s;
      transition: ease 0.15s; }
    #ts-main--classes #classes--search-panel #search-panel--nav a:first-child:before {
      right: -10%;
      width: 100%; }
    #ts-main--classes #classes--search-panel #search-panel--nav a:first-child {
      border-radius: 0 8px 0 0; }
    #ts-main--classes #classes--search-panel #search-panel--nav a:last-child:before {
      left: -10%;
      width: 100%; }
    #ts-main--classes #classes--search-panel #search-panel--nav a:last-child {
      border-radius: 8px 0 0 0; }
    #ts-main--classes #classes--search-panel #search-panel--nav a.active {
      cursor: auto; }
    #ts-main--classes #classes--search-panel #search-panel--con {
      overflow: hidden;
      display: inline-block;
      width: 100%;
      height: 220px;
      border-radius: 0 0 8px 8px; }
      #ts-main--classes #classes--search-panel #search-panel--con .screen {
        position: relative;
        z-index: -1;
        opacity: 0;
        -webkit-transform: translate(0px, 30px);
        -moz-transform: translate(0px, 30px);
        -ms-transform: translate(0px, 30px);
        -o-transform: translate(0px, 30px);
        transform: translate(0px, 30px);
        -webkit-transition: cubic-bezier(0.4, 0.1, 0, 1.6) 0.35s;
        -moz-transition: cubic-bezier(0.4, 0.1, 0, 1.6) 0.35s;
        -ms-transition: cubic-bezier(0.4, 0.1, 0, 1.6) 0.35s;
        -o-transition: cubic-bezier(0.4, 0.1, 0, 1.6) 0.35s;
        transition: cubic-bezier(0.4, 0.1, 0, 1.6) 0.35s; }
      #ts-main--classes #classes--search-panel #search-panel--con .screen.active {
        z-index: 1;
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
        -moz-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        -o-transform: translate(0px, 0px);
        transform: translate(0px, 0px); }
  #ts-main--classes #classes--search-panel.ts-mc-1--bc #search-panel--con,
  #ts-main--classes #classes--search-panel.ts-mc-1--bc #search-panel--nav a.active:before {
    background-color: #f7556d; }
  #ts-main--classes #classes--search-panel.ts-mc-1--bc #search-panel--nav a:hover:before {
    background-color: #f63d58; }
  #ts-main--classes #classes--search-panel.ts-mc-1--bc #search-panel--nav a.active:hover:before {
    background-color: #f7556d; }
  #ts-main--classes #classes--search-panel.ts-mc-2--bc #search-panel--con,
  #ts-main--classes #classes--search-panel.ts-mc-2--bc #search-panel--nav a.active:before {
    background-color: #f79a5e; }
  #ts-main--classes #classes--search-panel.ts-mc-2--bc #search-panel--nav a:hover:before {
    background-color: #f68b46; }
  #ts-main--classes #classes--search-panel.ts-mc-2--bc #search-panel--nav a.active:hover:before {
    background-color: #f79a5e; }
  #ts-main--classes #classes--search-panel.ts-mc-3--bc #search-panel--con,
  #ts-main--classes #classes--search-panel.ts-mc-3--bc #search-panel--nav a.active:before {
    background-color: #aec742; }
  #ts-main--classes #classes--search-panel.ts-mc-3--bc #search-panel--nav a:hover:before {
    background-color: #a0b937; }
  #ts-main--classes #classes--search-panel.ts-mc-3--bc #search-panel--nav a.active:hover:before {
    background-color: #aec742; }
  #ts-main--classes #classes--search-panel.ts-mc-4--bc #search-panel--con,
  #ts-main--classes #classes--search-panel.ts-mc-4--bc #search-panel--nav a.active:before {
    background-color: #35afd0; }
  #ts-main--classes #classes--search-panel.ts-mc-4--bc #search-panel--nav a:hover:before {
    background-color: #2ca0bf; }
  #ts-main--classes #classes--search-panel.ts-mc-4--bc #search-panel--nav a.active:hover:before {
    background-color: #35afd0; }
  #ts-main--classes #classes--search-panel.ts-mc-5--bc #search-panel--con,
  #ts-main--classes #classes--search-panel.ts-mc-5--bc #search-panel--nav a.active:before {
    background-color: #7a9fef; }
  #ts-main--classes #classes--search-panel.ts-mc-5--bc #search-panel--nav a:hover:before {
    background-color: #638fec; }
  #ts-main--classes #classes--search-panel.ts-mc-5--bc #search-panel--nav a.active:hover:before {
    background-color: #7a9fef; }
  #ts-main--classes #classes--search-panel.ts-mc-6--bc #search-panel--con,
  #ts-main--classes #classes--search-panel.ts-mc-6--bc #search-panel--nav a.active:before {
    background-color: #d77ebb; }
  #ts-main--classes #classes--search-panel.ts-mc-6--bc #search-panel--nav a:hover:before {
    background-color: #d16bb1; }
  #ts-main--classes #classes--search-panel.ts-mc-6--bc #search-panel--nav a.active:hover:before {
    background-color: #d77ebb; }
  #ts-main--classes #classes--search-panel.ts-mc-7--bc #search-panel--con,
  #ts-main--classes #classes--search-panel.ts-mc-7--bc #search-panel--nav a.active:before {
    background-color: #fe7a8d; }
  #ts-main--classes #classes--search-panel.ts-mc-7--bc #search-panel--nav a:hover:before {
    background-color: #fe6177; }
  #ts-main--classes #classes--search-panel.ts-mc-7--bc #search-panel--nav a.active:hover:before {
    background-color: #fe7a8d; }
  #ts-main--classes #classes--search-panel.ts-mc-8--bc #search-panel--con,
  #ts-main--classes #classes--search-panel.ts-mc-8--bc #search-panel--nav a.active:before {
    background-color: #bbb9c1; }
  #ts-main--classes #classes--search-panel.ts-mc-8--bc #search-panel--nav a:hover:before {
    background-color: #aeabb5; }
  #ts-main--classes #classes--search-panel.ts-mc-8--bc #search-panel--nav a.active:hover:before {
    background-color: #bbb9c1; }

.screen .screen--position {
  position: absolute;
  top: 0;
  right: 0; }

.screen .ts-hogim--panel-title {
  color: #fff;
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 10px;
  position: relative;
  top: -20px; }

.screen #ts-search--place, .screen #ts-search--date {
  padding: 10px 2.5%; }
  .screen #ts-search--place .form--bar, .screen #ts-search--date .form--bar {
    position: absolute;
    top: 117.5%;
    right: 0;
    width: 95%;
    margin: 0 2.5%; }

.screen .ts-hogim--panel {
  display: inline-block;
  width: 95%;
  padding: 40px 2.5%;
  text-align: center; }
  .screen .ts-hogim--panel .form-fields fieldset {
    border: none;
    height: 100px;
    color: #fff;
    font-size: 1.1em; }
    .screen .ts-hogim--panel .form-fields fieldset legend {
      display: block;
      text-align: right;
      font-weight: bold; }
    .screen .ts-hogim--panel .form-fields fieldset .form-fields--elements {
      display: block; }
      .screen .ts-hogim--panel .form-fields fieldset .form-fields--elements span {
        position: relative;
        display: inline-block;
        width: auto;
        height: 25px;
        line-height: 25px;
        margin: 5px;
        text-align: center; }
        .screen .ts-hogim--panel .form-fields fieldset .form-fields--elements span label {
          display: inline-block;
          position: relative;
          top: 0;
          left: 0;
          width: auto;
          white-space: nowrap;
          padding: 2px 10%;
          border-radius: 5px;
          text-align: right;
          background: #fff;
          color: #333333;
          -webkit-transition: ease 0.2s;
          -moz-transition: ease 0.2s;
          -ms-transition: ease 0.2s;
          -o-transition: ease 0.2s;
          transition: ease 0.2s; }
          .screen .ts-hogim--panel .form-fields fieldset .form-fields--elements span label:after {
            display: inline-block;
            margin-right: 15px;
            content: ' \f055';
            -webkit-transition: ease 0.2s;
            -moz-transition: ease 0.2s;
            -ms-transition: ease 0.2s;
            -o-transition: ease 0.2s;
            transition: ease 0.2s; }
        .screen .ts-hogim--panel .form-fields fieldset .form-fields--elements span input[type="checkbox"] {
          position: absolute;
          z-index: -1; }
        .screen .ts-hogim--panel .form-fields fieldset .form-fields--elements span input[type="checkbox"]:checked ~ label {
          color: #fff;
          background: #333333; }
          .screen .ts-hogim--panel .form-fields fieldset .form-fields--elements span input[type="checkbox"]:checked ~ label:after {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg); }
  .screen .ts-hogim--panel .form--bar {
    border-top: 1px solid #fff;
    text-align: right;
    padding: 25px 0;
    color: #fff; }
    .screen .ts-hogim--panel .form--bar label {
      font-weight: bold;
      font-size: 1.1em; }
    .screen .ts-hogim--panel .form--bar input {
      font-family: 'Alef', sans-serif;
      color: #333333;
      font-size: 1em;
      font-weight: bold;
      text-align: center;
      background: #fff;
      border-radius: 5px;
      margin: 0 10px;
      width: 120px;
      height: 30px; }
    .screen .ts-hogim--panel .form--bar input[type="submit"] {
      width: 150px;
      color: #fff;
      background: #333333; }
      .screen .ts-hogim--panel .form--bar input[type="submit"]:hover {
        background: black; }
    .screen .ts-hogim--panel .form--bar span:last-child {
      float: left; }

#ts-search--category ul li {
  width: 19.8%;
  border-left: 1px solid #e6e6e6; }
  #ts-search--category ul li a {
    display: block;
    height: auto;
    padding: 10%;
    color: #fff; }
    #ts-search--category ul li a .category--item .item--icon {
      position: relative;
      top: 0px;
      display: block;
      font-size: 3.7em;
      -webkit-transition: ease 0.15s;
      -moz-transition: ease 0.15s;
      -ms-transition: ease 0.15s;
      -o-transition: ease 0.15s;
      transition: ease 0.15s; }
    #ts-search--category ul li a .category--item .item--content {
      display: block;
      font-size: 13pt; }
  #ts-search--category ul li a:hover .item--icon {
    top: -15px; }

#ts-search--category ul li:last-child {
  border-width: 0px; }

#ts-search--age #range {
  height: 80px;
  width: 95%;
  margin: 0 2.5%;
  margin-top: 20px; }

.ts-headline--strip.matnas-page {
  height: 620px; }

.matnas-page-inside {
  height: 220px; }

.ts-matnas--nav-bar {
  display: table;
  width: calc(70% - 30px);
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 15px 15px; }
  .ts-matnas--nav-bar .nav-bar--logo {
    display: table-cell;
    vertical-align: middle;
    height: 60px; }
    .ts-matnas--nav-bar .nav-bar--logo h1, .ts-matnas--nav-bar .nav-bar--logo h2 {
      display: inline;
      vertical-align: middle;
      height: 60px;
      line-height: 35px;
      border-right: 2px solid #b2b2b2;
      padding: 0 15px;
      margin: 0 15px; }
    .ts-matnas--nav-bar .nav-bar--logo img {
      display: inline;
      vertical-align: middle;
      width: auto;
      height: 100%; }
  .ts-matnas--nav-bar .nav-bar--nav {
    display: table-cell;
    vertical-align: middle;
    height: 60px;
    line-height: 35px; }
    .ts-matnas--nav-bar .nav-bar--nav li {
      margin-left: 5px; }
    .ts-matnas--nav-bar .nav-bar--nav a {
      z-index: 1;
      position: relative;
      color: #fff;
      background: transparent;
      padding: 4px 20px; }
      .ts-matnas--nav-bar .nav-bar--nav a:before {
        z-index: -1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        -webkit-transform: skew(-30deg, 0deg);
        -moz-transform: skew(-30deg, 0deg);
        -ms-transform: skew(-30deg, 0deg);
        -o-transform: skew(-30deg, 0deg);
        transform: skew(-30deg, 0deg);
        -webkit-transition: ease 0.2s;
        -moz-transition: ease 0.2s;
        -ms-transition: ease 0.2s;
        -o-transition: ease 0.2s;
        transition: ease 0.2s; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-1--bc:before {
        background-color: #f7556d; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-2--bc:before {
        background-color: #f79a5e; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-3--bc:before {
        background-color: #aec742; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-4--bc:before {
        background-color: #35afd0; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-5--bc:before {
        background-color: #7a9fef; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-6--bc:before {
        background-color: #d77ebb; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-7--bc:before {
        background-color: #fe7a8d; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-8--bc:before {
        background-color: #bbb9c1; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-1--bc:hover:before {
        background-color: #f52443; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-2--bc:hover:before {
        background-color: #f57c2d; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-3--bc:hover:before {
        background-color: #8fa531; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-4--bc:hover:before {
        background-color: #288faa; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-5--bc:hover:before {
        background-color: #4c7eea; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-6--bc:hover:before {
        background-color: #cb57a6; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-7--bc:hover:before {
        background-color: #fe4762; }
      .ts-matnas--nav-bar .nav-bar--nav a.ts-mc-8--bc:hover:before {
        background-color: #a19ea9; }

.page-title.ts-mc-1--bc {
  background: #f52443; }

.page-title.ts-mc-2--bc {
  background: #f57c2d; }

.page-title.ts-mc-3--bc {
  background: #8fa531; }

.page-title.ts-mc-4--bc {
  background: #288faa; }

.page-title.ts-mc-5--bc {
  background: #4c7eea; }

.page-title.ts-mc-6--bc {
  background: #cb57a6; }

.page-title.ts-mc-7--bc {
  background: #fe4762; }

.page-title.ts-mc-8--bc {
  background: #a19ea9; }

.ts-contact--map {
  overflow: hidden;
  float: right;
  width: 30%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1); }
  .ts-contact--map iframe {
    float: right;
    width: 100%;
    height: 200px; }
  .ts-contact--map .ts-contact--map-content {
    clear: both;
    padding: 5px; }
    .ts-contact--map .ts-contact--map-content.ts-mc-1--bc {
      background: #fdcfd6; }
    .ts-contact--map .ts-contact--map-content.ts-mc-2--bc {
      background: #fde6d7; }
    .ts-contact--map .ts-contact--map-content.ts-mc-3--bc {
      background: #d8e4a4; }
    .ts-contact--map .ts-contact--map-content.ts-mc-4--bc {
      background: #9cd8e8; }
    .ts-contact--map .ts-contact--map-content.ts-mc-5--bc {
      background: #ecf1fd; }
    .ts-contact--map .ts-contact--map-content.ts-mc-6--bc {
      background: #f5dfee; }
    .ts-contact--map .ts-contact--map-content.ts-mc-7--bc {
      background: #fff9f9; }
    .ts-contact--map .ts-contact--map-content.ts-mc-8--bc {
      background: #fdfdfd; }

.ts-contact--content {
  float: left;
  width: 67.5%; }

.ts-contact--form {
  margin-top: 20px; }
  .ts-contact--form:after {
    content: "";
    display: block;
    clear: both; }

.ts-info--content {
  float: right;
  width: 100%; }
  .ts-info--content:after {
    content: "";
    display: block;
    clear: both; }

.content-images {
  margin-bottom: 20px; }
  .content-images .content-images--image {
    float: right;
    width: 25%; }
    .content-images .content-images--image img {
      display: block;
      width: 95%;
      margin: 0 auto;
      border: 1px solid #cccccc;
      border-radius: 4px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden; }
    .content-images .content-images--image:last-child {
      margin-left: 0%; }
  .content-images:after {
    content: "";
    display: block;
    clear: both; }

.ts-department-info {
  float: right;
  width: 60%; }

.ts-department-info:after {
  content: "";
  display: block;
  clear: both; }

.swiper-container.ts-gallery-department {
  float: left; }

.ts-department-nav {
  float: right;
  width: 100%;
  margin-top: 20px; }
  .ts-department-nav .ts-content-title {
    border-bottom: 1px solid #cccccc;
    font-size: 1.4em;
    background: transparent;
    margin-bottom: 10px; }
    .ts-department-nav .ts-content-title.ts-mc-1--bc {
      color: #f40c2e; }
    .ts-department-nav .ts-content-title.ts-mc-2--bc {
      color: #f36c15; }
    .ts-department-nav .ts-content-title.ts-mc-3--bc {
      color: #7e912b; }
    .ts-department-nav .ts-content-title.ts-mc-4--bc {
      color: #237d96; }
    .ts-department-nav .ts-content-title.ts-mc-5--bc {
      color: #366ee7; }
    .ts-department-nav .ts-content-title.ts-mc-6--bc {
      color: #c5449c; }
    .ts-department-nav .ts-content-title.ts-mc-7--bc {
      color: #fd2e4c; }
    .ts-department-nav .ts-content-title.ts-mc-8--bc {
      color: #94909d; }

.block-border--title.ts-mc-1--bc {
  color: #b50922;
  background: #fcc0c9; }

.block-border--title.ts-mc-2--bc {
  color: #bd5009;
  background: #fcddc9; }

.block-border--title.ts-mc-3--bc {
  color: #525e1c;
  background: #d3e199; }

.block-border--title.ts-mc-4--bc {
  color: #165060;
  background: #90d3e5; }

.block-border--title.ts-mc-5--bc {
  color: #174ec3;
  background: #dee7fb; }

.block-border--title.ts-mc-6--bc {
  color: #972f76;
  background: #f2d4e8; }

.block-border--title.ts-mc-7--bc {
  color: #e70223;
  background: #ffe9ec; }

.block-border--title.ts-mc-8--bc {
  color: #726e7d;
  background: #f5f5f6; }

.ts-department-nav--list, .ts-department-nav--list ul {
  float: right;
  width: 100%; }
  .ts-department-nav--list ul li {
    width: 20%;
    padding: 10px;
    text-align: center;
    box-sizing: border-box; }
    .ts-department-nav--list ul li .ts-department-nav--item {
      color: #fff;
      font-weight: 700;
      padding: 20% 0;
      border-radius: 4px;
      border: 1px solid #cccccc;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      -webkit-transition: ease 0.2s;
      -moz-transition: ease 0.2s;
      -ms-transition: ease 0.2s;
      -o-transition: ease 0.2s;
      transition: ease 0.2s; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-1--bc {
        background: #f7556d; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-1--bc i {
        color: #f52443; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-2--bc {
        background: #f79a5e; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-2--bc i {
        color: #f57c2d; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-3--bc {
        background: #aec742; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-3--bc i {
        color: #8fa531; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-4--bc {
        background: #35afd0; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-4--bc i {
        color: #288faa; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-5--bc {
        background: #7a9fef; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-5--bc i {
        color: #4c7eea; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-6--bc {
        background: #d77ebb; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-6--bc i {
        color: #cb57a6; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-7--bc {
        background: #fe7a8d; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-7--bc i {
        color: #fe4762; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-8--bc {
        background: #bbb9c1; }
      .ts-department-nav--list ul li .ts-department-nav--item.ts-mc-8--bc i {
        color: #a19ea9; }
      .ts-department-nav--list ul li .ts-department-nav--item i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 2em;
        color: black;
        background: #fff;
        border-radius: 100%;
        -webkit-transition: ease 0.5s;
        -moz-transition: ease 0.5s;
        -ms-transition: ease 0.5s;
        -o-transition: ease 0.5s;
        transition: ease 0.5s; }
      .ts-department-nav--list ul li .ts-department-nav--item:hover.ts-mc-1--bc {
        background: #f52443; }
      .ts-department-nav--list ul li .ts-department-nav--item:hover.ts-mc-2--bc {
        background: #f57c2d; }
      .ts-department-nav--list ul li .ts-department-nav--item:hover.ts-mc-3--bc {
        background: #8fa531; }
      .ts-department-nav--list ul li .ts-department-nav--item:hover.ts-mc-4--bc {
        background: #288faa; }
      .ts-department-nav--list ul li .ts-department-nav--item:hover.ts-mc-5--bc {
        background: #4c7eea; }
      .ts-department-nav--list ul li .ts-department-nav--item:hover.ts-mc-6--bc {
        background: #cb57a6; }
      .ts-department-nav--list ul li .ts-department-nav--item:hover.ts-mc-7--bc {
        background: #fe4762; }
      .ts-department-nav--list ul li .ts-department-nav--item:hover.ts-mc-8--bc {
        background: #a19ea9; }
      .ts-department-nav--list ul li .ts-department-nav--item:hover i {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transform: rotateY(180deg); }
      .ts-department-nav--list ul li .ts-department-nav--item .ts-department-nav--item-t {
        height: 25px;
        line-height: 1.1em;
        font-size: 1.05em;
        padding: 0 10px;
        margin-top: 10px; }

.swiper-container.ts-gallery-he {
  float: right; }

.ts-he-info {
  float: left;
  width: 60%; }

.ts-he-info:after {
  content: "";
  display: block;
  clear: both; }

.ts-he-deatils {
  margin-top: 20px;
  margin-bottom: 0px; }
  .ts-he-deatils .ts-he-deatils--table {
    width: 100%;
    text-align: center;
    font-size: 1em; }
    .ts-he-deatils .ts-he-deatils--table td {
      background: transparent; }
      .ts-he-deatils .ts-he-deatils--table td.ts-mc-1--bc:before {
        background: #fdcfd6;
        color: #ab0820; }
      .ts-he-deatils .ts-he-deatils--table td.ts-mc-2--bc:before {
        background: #fde6d7;
        color: #b34c09; }
      .ts-he-deatils .ts-he-deatils--table td.ts-mc-3--bc:before {
        background: #d8e4a4;
        color: #4b561a; }
      .ts-he-deatils .ts-he-deatils--table td.ts-mc-4--bc:before {
        background: #9cd8e8;
        color: #144958; }
      .ts-he-deatils .ts-he-deatils--table td.ts-mc-5--bc:before {
        background: #ecf1fd;
        color: #164aba; }
      .ts-he-deatils .ts-he-deatils--table td.ts-mc-6--bc:before {
        background: #f5dfee;
        color: #902c70; }
      .ts-he-deatils .ts-he-deatils--table td.ts-mc-7--bc:before {
        background: #fff9f9;
        color: #dd0221; }
      .ts-he-deatils .ts-he-deatils--table td.ts-mc-8--bc:before {
        background: #fdfdfd;
        color: #6d6a77; }
  .ts-he-deatils .ts-he-deatils--button {
    display: inline-block;
    margin: 5px 0;
    padding: 4px 30px;
    font-weight: 700;
    color: #fff;
    border-radius: 2px;
    background: #aec742;
    -webkit-transition: ease 0.15s;
    -moz-transition: ease 0.15s;
    -ms-transition: ease 0.15s;
    -o-transition: ease 0.15s;
    transition: ease 0.15s; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-1--bc {
      background: #f7556d; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-1--bc:hover {
      background: #f52443; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-2--bc {
      background: #f79a5e; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-2--bc:hover {
      background: #f57c2d; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-3--bc {
      background: #aec742; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-3--bc:hover {
      background: #8fa531; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-4--bc {
      background: #35afd0; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-4--bc:hover {
      background: #288faa; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-5--bc {
      background: #7a9fef; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-5--bc:hover {
      background: #4c7eea; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-6--bc {
      background: #d77ebb; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-6--bc:hover {
      background: #cb57a6; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-7--bc {
      background: #fe7a8d; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-7--bc:hover {
      background: #fe4762; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-8--bc {
      background: #bbb9c1; }
    .ts-he-deatils .ts-he-deatils--button.ts-mc-8--bc:hover {
      background: #a19ea9; }
    .ts-he-deatils .ts-he-deatils--button:hover {
      background: #8fa531; }

.ts-tabs-nav .ts-tabs-nav--list, .ts-tabs-nav .ts-tabs-nav--list ul {
  float: right;
  width: 100%; }
  .ts-tabs-nav .ts-tabs-nav--list ul li {
    float: right;
    width: 33.333%;
    box-sizing: border-box;
    padding: 20px 5px 0 5px; }
    .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(3n+1) {
      padding: 20px 0 0 10px; }
    .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(3n+3) {
      padding: 20px 10px 0 0; }
    .ts-tabs-nav .ts-tabs-nav--list ul li a, .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item {
      width: 100%;
      height: 90px;
      float: right;
      text-align: center; }
    .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-i {
      float: right;
      width: 40%;
      height: 100%; }
      .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-i .block-border {
        position: relative;
        height: 100%;
        overflow: hidden; }
      .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-i img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        margin: auto;
        height: 100%;
        width: auto;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        -webkit-transition: cubic-bezier(0.05, 0.7, 0.4, 1) 0.15s;
        -moz-transition: cubic-bezier(0.05, 0.7, 0.4, 1) 0.15s;
        -ms-transition: cubic-bezier(0.05, 0.7, 0.4, 1) 0.15s;
        -o-transition: cubic-bezier(0.05, 0.7, 0.4, 1) 0.15s;
        transition: cubic-bezier(0.05, 0.7, 0.4, 1) 0.15s; }
    .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-t {
      box-sizing: border-box;
      float: left;
      width: 60%;
      height: 100%;
      padding-right: 10px;
      font-size: 1.05em; }
      .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-t .block-border {
        display: table;
        height: 100%;
        -webkit-transition: ease 0.15s;
        -moz-transition: ease 0.15s;
        -ms-transition: ease 0.15s;
        -o-transition: ease 0.15s;
        transition: ease 0.15s; }
        .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-t .block-border.ts-mc-1--bc {
          background: #fdcfd6;
          color: #ab0820; }
        .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-t .block-border.ts-mc-2--bc {
          background: #fde6d7;
          color: #b34c09; }
        .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-t .block-border.ts-mc-3--bc {
          background: #d8e4a4;
          color: #4b561a; }
        .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-t .block-border.ts-mc-4--bc {
          background: #9cd8e8;
          color: #144958; }
        .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-t .block-border.ts-mc-5--bc {
          background: #ecf1fd;
          color: #164aba; }
        .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-t .block-border.ts-mc-6--bc {
          background: #f5dfee;
          color: #902c70; }
        .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-t .block-border.ts-mc-7--bc {
          background: #fff9f9;
          color: #dd0221; }
        .ts-tabs-nav .ts-tabs-nav--list ul li .ts-tabs-nav--item-t .block-border.ts-mc-8--bc {
          background: #fdfdfd;
          color: #6d6a77; }
    .ts-tabs-nav .ts-tabs-nav--list ul li .block-border--content {
      display: table-cell;
      vertical-align: middle;
      line-height: 20px; }
    .ts-tabs-nav .ts-tabs-nav--list ul li a:hover .block-border.ts-mc-1--bc {
      background: #f7556d;
      color: #fff; }
    .ts-tabs-nav .ts-tabs-nav--list ul li a:hover .block-border.ts-mc-2--bc {
      background: #f79a5e;
      color: #fff; }
    .ts-tabs-nav .ts-tabs-nav--list ul li a:hover .block-border.ts-mc-3--bc {
      background: #aec742;
      color: #fff; }
    .ts-tabs-nav .ts-tabs-nav--list ul li a:hover .block-border.ts-mc-4--bc {
      background: #35afd0;
      color: #fff; }
    .ts-tabs-nav .ts-tabs-nav--list ul li a:hover .block-border.ts-mc-5--bc {
      background: #7a9fef;
      color: #fff; }
    .ts-tabs-nav .ts-tabs-nav--list ul li a:hover .block-border.ts-mc-6--bc {
      background: #d77ebb;
      color: #fff; }
    .ts-tabs-nav .ts-tabs-nav--list ul li a:hover .block-border.ts-mc-7--bc {
      background: #fe7a8d;
      color: #fff; }
    .ts-tabs-nav .ts-tabs-nav--list ul li a:hover .block-border.ts-mc-8--bc {
      background: #bbb9c1;
      color: #fff; }
    .ts-tabs-nav .ts-tabs-nav--list ul li a:hover img {
      height: 130%; }

.ts-jobs--table tr:nth-child(n+2) td {
  border-top: 1px solid #ddd; }

.ts-jobs--table tr.ts--table-hidden-tr td {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
  border-top: 1px solid #fff; }
  .ts-jobs--table tr.ts--table-hidden-tr td:before {
    display: none; }

.ts-jobs--table tr {
  background: transparent;
  -webkit-transition: ease 0.15s;
  -moz-transition: ease 0.15s;
  -ms-transition: ease 0.15s;
  -o-transition: ease 0.15s;
  transition: ease 0.15s; }
  .ts-jobs--table tr td {
    border-bottom: 1px solid transparent; }
  .ts-jobs--table tr.active.ts-mc-1--bc {
    background: #fee7ea;
    color: #dc0a29; }
  .ts-jobs--table tr.active.ts-mc-2--bc {
    background: #fef5f0;
    color: #e4600b; }
  .ts-jobs--table tr.active.ts-mc-3--bc {
    background: #e1eab8;
    color: #6d7e25; }
  .ts-jobs--table tr.active.ts-mc-4--bc {
    background: #b1e0ed;
    color: #1e6c81; }
  .ts-jobs--table tr.active.ts-mc-5--bc {
    background: white;
    color: #1f5de4; }
  .ts-jobs--table tr.active.ts-mc-6--bc {
    background: #fbf3f9;
    color: #b6398f; }
  .ts-jobs--table tr.active.ts-mc-7--bc {
    background: white;
    color: #fd1536; }
  .ts-jobs--table tr.active.ts-mc-8--bc {
    background: white;
    color: #868391; }
  .ts-jobs--table tr.active td {
    border-bottom: 1px solid #ddd; }

.ts-jobs--table .ts--table-hidden-box {
  height: auto;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  box-shadow: inset 0px -5px 10px 0px rgba(0, 0, 0, 0.07);
  -webkit-transition: ease 0.4s;
  -moz-transition: ease 0.4s;
  -ms-transition: ease 0.4s;
  -o-transition: ease 0.4s;
  transition: ease 0.4s; }
  .ts-jobs--table .ts--table-hidden-box.active {
    max-height: 999px;
    visibility: visible;
    -webkit-transition: ease-out 1.2s;
    -moz-transition: ease-out 1.2s;
    -ms-transition: ease-out 1.2s;
    -o-transition: ease-out 1.2s;
    transition: ease-out 1.2s; }
  .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-right,
  .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-left {
    box-sizing: border-box;
    padding: 20px 45px;
    width: 50%; }
  .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-right {
    float: right; }
  .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-left {
    float: left;
    border-right: 1px solid #ddd;
    background: #fafafa; }
  .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-title {
    display: block;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    text-align: right;
    background: transparent; }
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-title.ts-mc-1--bc {
      color: #dc0a29; }
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-title.ts-mc-2--bc {
      color: #e4600b; }
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-title.ts-mc-3--bc {
      color: #6d7e25; }
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-title.ts-mc-4--bc {
      color: #1e6c81; }
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-title.ts-mc-5--bc {
      color: #1f5de4; }
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-title.ts-mc-6--bc {
      color: #b6398f; }
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-title.ts-mc-7--bc {
      color: #fd1536; }
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-title.ts-mc-8--bc {
      color: #868391; }
  .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-content {
    margin-bottom: 20px;
    font-size: 1em; }
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-content a {
      color: #1190bc;
      text-decoration: underline; }
      .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-content a:hover {
        color: #0d6e8f; }
  .ts-jobs--table .ts--table-hidden-box .ts--table-button-close {
    padding-right: 45px;
    padding-left: 45px;
    background: #b9b9b9; }
    .ts-jobs--table .ts--table-hidden-box .ts--table-button-close:hover {
      background: #a5a5a5; }

.ts-he-deatils--table tr .ts-he-deatils--table-name.ts-mc-1--bc {
  background: #fddde2;
  color: #b50922; }

.ts-he-deatils--table tr .ts-he-deatils--table-name.ts-mc-2--bc {
  background: #feefe6;
  color: #bd5009; }

.ts-he-deatils--table tr .ts-he-deatils--table-name.ts-mc-3--bc {
  background: #dde8b0;
  color: #525e1c; }

.ts-he-deatils--table tr .ts-he-deatils--table-name.ts-mc-4--bc {
  background: #a9ddeb;
  color: #165060; }

.ts-he-deatils--table tr .ts-he-deatils--table-name.ts-mc-5--bc {
  background: #f9fbfe;
  color: #174ec3; }

.ts-he-deatils--table tr .ts-he-deatils--table-name.ts-mc-6--bc {
  background: #f9ebf4;
  color: #972f76; }

.ts-he-deatils--table tr .ts-he-deatils--table-name.ts-mc-7--bc {
  background: white;
  color: #e70223; }

.ts-he-deatils--table tr .ts-he-deatils--table-name.ts-mc-8--bc {
  background: white;
  color: #726e7d; }

.ts-he-deatils--table tr td {
  padding-left: 5px;
  padding-right: 5px;
  border-top: 1px solid #ddd; }
  .ts-he-deatils--table tr td .ts-he-deatils--button {
    padding: 4px 20px; }

.ts-he-deatils--table tr:first-child td {
  border-top: none; }

.ts-he-deatils--title {
  color: #fff;
  font-size: 1.1em;
  padding: 5px 10px; }

.ts-searching-box .block-border--content {
  float: right;
  padding: 20px; }

.ts-searching-container form {
  display: table;
  width: 100%; }
  .ts-searching-container form input[type="submit"] {
    color: #fff;
    font-weight: bold;
    padding: 6px 25px;
    border-radius: 3px; }

.ts-searching-container .ts-searching-col {
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px; }
  .ts-searching-container .ts-searching-col label {
    font-size: 1.05em; }

.ts-searching-container .ts-submit-col {
  text-align: left;
  vertical-align: bottom; }

.ts-searching-container .ts-input-searching {
  z-index: 1;
  position: relative;
  display: inline-block;
  margin-right: 5px; }
  .ts-searching-container .ts-input-searching:after {
    z-index: -1;
    content: "\f107";
    position: absolute;
    top: 50%;
    left: 4px;
    background: #ddd;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 3px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); }
  .ts-searching-container .ts-input-searching select {
    width: 100%;
    border: 1px solid #b5b5b5;
    padding: 6px 10px;
    padding-left: 30px;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.clendario,
.clendarioTitle,
.clendarioLast,
.clendarioThisMunth,
.clendarioNext,
.clendarioTitle img,
.clendarioDaysRow,
.clendarioMunthDays {
  width: auto !important;
  margin: 0 !important;
  float: none !important;
  color: #000 !important;
  font-size: 1em !important;
  font-weight: normal !important;
  background: transparent !important; }

.clendario {
  overflow: scroll; }

.clendarioDaysRow,
.clendarioMunthDays {
  min-width: 800px; }

.clendarioTitle,
.clendarioDaysRow,
.clendarioMunthDays {
  display: table;
  overflow: hidden;
  box-sizing: border-box;
  width: 100% !important;
  margin-bottom: 20px !important;
  padding: 10px 20px;
  background: #fff !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); }

.clendarioDaysRow {
  background: #efefef !important; }

.clendarioLast,
.clendarioThisMunth,
.clendarioNext,
.dayTop {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.clendarioThisMunth {
  font-size: 1.4em !important; }

.clendarioLast {
  text-align: right; }

.clendarioNext {
  text-align: left; }

.clendarioMunthDays {
  padding: 0; }

.dayBox {
  overflow: scroll;
  box-sizing: border-box;
  padding: 10px;
  float: right !important;
  width: 14.28% !important;
  height: 200px;
  border: 5px solid #fff;
  box-shadow: 0px 0px 1px 1px #ddd; }

.curantEvent {
  background: #3798d4;
  padding: 5px 10px;
  line-height: 1.2em;
  margin-top: 10px; }

.slideshow-open .slideshow {
  z-index: 999999999; }

.ts-galleries {
  width: 100%;
  float: right;
  display: flex;
  flex-wrap: wrap; }

.grid-gallery {
  width: 100%; }

.grid li:hover figcaption {
  background: #1e6c81 !important; }

.grid figcaption {
  border-bottom: none !important; }

.grid figure {
  overflow: hidden;
  margin: 30px 15px 0 !important;
  padding: 0 !important;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); }

.slideshow figure {
  border-radius: 10px; }

.imageFolderWrapper {
  width: 33.333%;
  box-sizing: border-box;
  padding: 30px 15px 0; }

.imageFolder {
  overflow: hidden;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); }

.imageFolderImage {
  overflow: hidden;
  height: 200px;
  background: #ddd; }
  .imageFolderImage img {
    display: block;
    width: 100% !important;
    height: auto !important; }

.imageFolderTitle {
  border-top: 1px solid #ddd;
  padding: 10px; }

.imageFolderWrapper:hover .imageFolderTitle {
  color: #fff;
  background: #1e6c81; }

@media screen and (max-width: 1280px) {
  .imageFolderWrapper {
    width: 50%; } }

@media screen and (max-width: 500px) {
  .imageFolderWrapper {
    width: 100%; }
  .imageFolderImage {
    height: 150px; } }

@media only screen and (max-width: 1560px) {
  .ts-main--width {
    max-width: 1280px; }
  #ts-side--con {
    width: 350px; }
  .swiper-content p {
    padding: 0 45px 0 40% !important; }
  #header-top--menu li .menu-sub {
    width: 350%; } }

@media only screen and (max-width: 1380px) {
  .ts-main--width {
    max-width: 1080px; }
  #header-top--strip {
    height: 55px;
    margin-top: 10px; }
  #header-top--strip #strip-logo {
    width: 200px; }
  #header-top--menu {
    height: 35px;
    margin-bottom: 5px; }
  #header-top--menu li .menu-button {
    font-size: 14px; }
  #header-top--menu li:nth-child(n+2):nth-child(even) .menu-sub {
    right: -100%; }
  #header-top--nav {
    height: 25px;
    line-height: 25px;
    font-size: 0.9em; }
  #ts-side--con #side--title {
    font-size: 0.9em;
    height: 40px;
    line-height: 40px; }
  #ts-side--con #side--title.icon-nav:before {
    width: 40px;
    height: 40px;
    line-height: 42px;
    font-size: 1.5em; }
  #ts-side--con #side--buttons {
    width: 95%;
    padding: 2.5%; }
  #ts-side--con #side--buttons a {
    height: 35px;
    line-height: 35px; }
  #ts-side--con #side--news {
    font-size: 0.9em; }
  #ts-side--con #side--search {
    padding: 5%; }
  #ts-side--con #side--nav li .side--nav-item {
    padding: 5%;
    font-size: 0.9em; }
  #ts-side--con #side--news ul li {
    line-height: 20px; }
  .swiper-container,
  .ts-main--width-right {
    width: 65%; }
  .events--item-con .events--item-box {
    width: 33.333%; }
  #ts-main--classes #classes--search-panel #search-panel--nav a:before {
    border-radius: 5px 5px 0 0;
    -webkit-transform: skew(0deg, 0deg);
    -moz-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg); }
  .screen .ts-hogim--panel .form-fields--elements span label {
    font-size: 0.9em;
    padding: 0px 10% !important; }
  .screen .ts-hogim--panel .form-fields--elements span {
    margin: 3px !important; } }

@media only screen and (max-width: 1080px) {
  .goToTop {
    display: none; }
  .ts-main--width {
    max-width: 800px;
    padding: 0 5%; }
  #header-top--menu li {
    width: 49.5%;
    margin: 5% 0.25%; }
  #header-top--menu li .menu-sub {
    width: 170%;
    padding: 10% 15%;
    font-size: 0.95em; }
  #header-top--menu {
    opacity: 0;
    height: 0px;
    overflow: hidden; }
  #header-top--menu.active {
    opacity: 1;
    height: 45px;
    overflow: visible; }
  #header-top--strip {
    overflow: hidden; }
  #header-top--strip.active {
    height: 0; }
  #ts-side--con {
    width: 400px;
    left: 50%;
    top: -100%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    /* Positioning create ts-layout-func.js > in sideBar() */
    transition: cubic-bezier(0.3, 0, 0, 1.04) 0.6s;
    -webkit-transition: cubic-bezier(0.3, 0, 0, 1.04) 0.6s;
    -moz-transition: cubic-bezier(0.3, 0, 0, 1.04) 0.6s;
    -ms-transition: cubic-bezier(0.3, 0, 0, 1.04) 0.6s;
    -o-transition: cubic-bezier(0.3, 0, 0, 1.04) 0.6s; }
  #ts-side--con.active {
    z-index: 99999;
    top: 0 !important; }
  .swiper-container, .ts-main--width-right {
    width: 100%; } }

@media only screen and (max-width: 650px) {
  .ts-mobile-hide {
    display: none; }
  .ts-mobile-show {
    display: inline-block !important; }
  .ts-headline--strip {
    height: 300px; }
  #header-top--menu li:hover .menu-sub,
  #header-top--menu li:hover .menu-sub,
  #header-top--menu li:focus .menu-sub,
  #header-top--menu li:focus .menu-sub {
    visibility: hidden !important;
    opacity: 0; }
  #header-top--menu li.active .menu-sub,
  #header-top--menu li.active .menu-sub {
    visibility: visible !important;
    opacity: 1; }
  #header-top--menu li .menu-sub li {
    width: 98%; }
  #header-top--menu li .menu-sub li :before {
    left: 2%; }
  #header-top--menu li:hover .menu-sub, #header-top--menu li:focus .menu-sub {
    opacity: 1; }
  .menu-sub ul {
    display: block;
    width: 100%;
    max-height: 155px;
    overflow: scroll; }
  .swiper-container {
    top: 10px; }
  .swiper-content {
    height: 90px; }
  .swiper-content .swiper-content--headline {
    font-size: 20px;
    margin-right: -55px; }
  .swiper-content p {
    font-size: 13px;
    line-height: 16px;
    padding: 0 45px 0 15% !important; }
  .swiper-content .swiper-content--link {
    padding: 60px 15px 10px 15px;
    font-size: 15px; }
  .swiper-container-rtl .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    font-size: 2em; }
  .swiper-button-prev, .swiper-button-next {
    top: 30px;
    width: 30px;
    height: 80px;
    line-height: 80px; }
  .swiper-container-rtl .swiper-button-next:hover {
    padding-right: 0px; }
  .swiper-container-rtl .swiper-button-prev:hover {
    padding-left: 0px; }
  .swiper-content .swiper-content--link {
    border-radius: 0 15px 0 0;
    padding: 0px 0px 0px 0px;
    width: 40px; }
  .swiper-content .swiper-content--link:before {
    width: 100%;
    border-right: 0px solid transparent;
    border-top: 0px solid transparent; }
  .swiper-content .swiper-content--link:after {
    content: '\f067';
    bottom: 12px;
    left: 14px;
    font-size: 15px;
    color: #fff; }
  .swiper-content .swiper-content--link a {
    color: transparent;
    padding-left: 0px;
    width: 100%; }
  .ts-main--title {
    font-size: 17px; }
  .events--item-con .events--item-box {
    width: 50%; }
  .events--item-con .events--item-box .events--item {
    width: 90%;
    padding: 10% 5%; }
  .events--item-con .events--item-box .events--item .events-item-box--con {
    height: 200px; }
  .events--item-con .events--item-box .events--item .events-item-box--title {
    font-size: 0.9em;
    line-height: 20px;
    min-height: 40px; }
  .events--item-con .events--item-box .events--item .events-item-box--link {
    height: 30px;
    line-height: 30px;
    font-size: 0.9em; }
  #ts-main--classes #classes--search-panel #search-panel--con {
    border-radius: 5px; }
  #ts-main--classes #classes--search-panel {
    padding: 5% 0; }
  #ts-search--category {
    padding: 40px 2.5%; }
  #ts-search--category ul li {
    border-width: 0; }
  #ts-search--category ul li a .category--item .item--icon {
    font-size: 2.5em; }
  #ts-search--category ul li a .category--item .item--content {
    font-size: 11pt; } }

@media only screen and (max-width: 570px) {
  #ts-main--classes #classes--search-panel #search-panel--con {
    height: 300px; }
  .controler {
    font-size: 0.8em; }
  #ts-search--age #range {
    margin-top: 60px; }
  #ts-search--category ul li {
    width: 33.33%; }
  .screen .form--bar {
    margin-top: 40px; }
  .screen #ts-search--place .form--bar, .screen #ts-search--date .form--bar {
    top: 180%; } }

@media only screen and (max-width: 480px) {
  #ts-side--con {
    width: 100%; }
  #ts-side--con #side--buttons {
    margin: 0; }
  #ts-side--con #side--buttons a {
    width: 47.5%; }
  #ts-side--con #side--news {
    display: none; }
  #ts-side--con #side--search form #search--type-submit {
    width: 25%; } }

@media only screen and (max-width: 1560px) {
  .ts-matnas--nav-bar .nav-bar--logo h1 {
    font-size: 1.6em; }
  .ts-matnas--nav-bar .nav-bar--nav a {
    padding: 4px 15px;
    font-size: 1em; } }

@media only screen and (max-width: 1380px) {
  .ts-matnas--nav-bar {
    width: calc(65% - 60px);
    padding: 15px 30px; }
  .ts-matnas--nav-bar,
  .ts-matnas--nav-bar .nav-bar--logo,
  .ts-matnas--nav-bar .nav-bar--nav {
    display: block; }
  .ts-matnas--nav-bar .nav-bar--nav {
    height: 35px;
    margin-top: 10px; }
  .ts-matnas--nav-bar .nav-bar--logo h1 {
    font-size: 1.8em; } }

@media only screen and (max-width: 1080px) {
  .ts-matnas--nav-bar {
    width: calc(100% - 60px); } }

@media only screen and (max-width: 650px) {
  .page-title {
    height: 40px; }
  .page-title h1 {
    font-size: 1.2em; }
  .ts-headline--strip.matnas-page {
    height: 540px; }
  .ts-matnas--nav-bar .nav-bar--nav {
    display: inline-block;
    height: auto;
    text-align: center; }
  .ts-matnas--nav-bar .nav-bar--logo {
    height: auto;
    text-align: center; }
  .ts-matnas--nav-bar .nav-bar--logo h1 {
    display: block;
    height: auto;
    border-width: 0;
    padding: 0 0px;
    margin: 0 0px;
    font-size: 1.5em; }
  .ts-matnas--nav-bar .nav-bar--logo img {
    width: 70px;
    height: auto;
    display: block;
    margin: 0 auto; }
  .ts-matnas--nav-bar .nav-bar--logo h2 {
    padding: 0;
    margin: 0;
    border: none; }
  .ts-matnas--nav-bar .nav-bar--nav li {
    float: none; }
  .ts-matnas--nav-bar .nav-bar--nav a {
    font-size: 0.9em; }
  .ts-matnas--nav-bar .nav-bar--nav a:before {
    -webkit-transform: skew(0deg, 0deg);
    -moz-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg); }
  .content-images .content-images--image:nth-child(n+2) {
    display: none; }
  .content-images .content-images--image {
    width: 100%; } }

@media only screen and (max-width: 650px) {
  .ts-contact--map {
    width: 100%;
    margin-bottom: 20px; }
  .ts-contact--content {
    width: 100%; }
  .ts-contact--content .block-border {
    font-size: 0.9em; } }

@media only screen and (max-width: 480px) {
  .ts-contact--map iframe {
    height: 100px; }
  .ts-input {
    width: 95%; } }

@media only screen and (max-width: 1380px) {
  .ts-department-nav--list ul li {
    width: 25%; }
  .swiper-container.ts-gallery .swiper-button-next,
  .swiper-container.ts-gallery .swiper-button-prev {
    top: 70px; } }

@media only screen and (max-width: 800px) {
  .ts-department-info,
  .ts-he-info,
  .swiper-container.ts-gallery {
    width: 100%; }
  .swiper-container.ts-gallery {
    margin-bottom: 20px; }
  .swiper-container.ts-gallery .swiper-button-next,
  .swiper-container.ts-gallery .swiper-button-prev {
    top: 110px; } }

@media only screen and (max-width: 650px) {
  .ts-department-nav--list ul li {
    width: 33.333%; } }

@media only screen and (max-width: 570px) {
  .swiper-container.ts-gallery .swiper-button-next,
  .swiper-container.ts-gallery .swiper-button-prev {
    top: 45px; }
  .block-border.half-width {
    width: calc(100% - 2px); }
  .block-border.half-width:nth-child(odd) {
    margin-left: 0%; }
  .ts-department-nav--list ul li .ts-department-nav--item i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.6em; } }

@media only screen and (max-width: 480px) {
  .ts-department-nav--list ul li {
    width: 50%;
    padding: 5px; } }

@media only screen and (max-width: 1380px) {
  .ts-tabs-nav .ts-tabs-nav--list ul li {
    width: 50%; }
  .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(3n+1),
  .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(3n+3) {
    padding: 20px 5px 0 5px; }
  .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(2n+2) {
    padding: 20px 10px 0 0; }
  .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(2n+1) {
    padding: 20px 0 0 10px; } }

@media only screen and (max-width: 730px) {
  .ts-tabs-nav .ts-tabs-nav--list ul li {
    width: 100%;
    font-size: 0.95em; }
  .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(2n+2) {
    padding: 20px 0 0 0; }
  .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(2n+1) {
    padding: 20px 0 0 0; } }

@media only screen and (max-width: 800px) {
  .ts-he-deatils--table .ts-padding-tb {
    padding: 0; }
  .ts-he-deatils--table,
  .ts-he-deatils--table thead,
  .ts-he-deatils--table tbody,
  .ts-he-deatils--table th,
  .ts-he-deatils--table td,
  .ts-he-deatils--table tr {
    display: block; }
  .ts-he-deatils--table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .ts-he-deatils--table tr td {
    border-bottom: 1px solid #cccccc;
    position: relative;
    padding: 15px 45% 15px 0; }
  .ts-he-deatils--table td:last-child {
    border: none; }
  .ts-he-deatils--table td:before {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    width: 40%;
    height: 100%;
    border-left: 1px solid #cccccc;
    padding-right: 10px;
    white-space: nowrap;
    transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%); }
  .ts-he-deatils--table td:before {
    content: attr(data-th);
    font-family: 'Alef', sans-serif;
    font-weight: 700; }
  .ts-he-deatils--table .ts-th-nomobile {
    padding: 15px 0 15px 0; }
  .ts-he-deatils--table .ts-th-nomobile .ts-he-deatils--button {
    padding: 6px 0;
    width: 90%; }
  .ts-he-deatils--table .ts-th-nomobile:before {
    display: none; } }

@media only screen and (max-width: 570px) {
  .ts-he-deatils .ts-he-deatils--table {
    font-size: 0.95em; } }

@media only screen and (max-width: 800px) {
  .ts-jobs--table tr:nth-child(n+2) td {
    border-top: none; }
  .ts-jobs--table tr:nth-child(n+2) td:first-child {
    border-top: 1px solid #ddd; }
  .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-right,
  .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-left {
    padding: 0px 2.5% 15px 2.5%;
    width: 100%; }
  .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-left {
    border-right: none;
    background: transparent; }
  .ts-jobs--table tr.active td:last-child {
    border-bottom: none; }
  .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-content {
    margin-bottom: 10px; } }

@media only screen and (max-width: 1380px) {
  .ts-he-deatils .ts-he-deatils--table {
    font-size: 0.95em; } }

@media only screen and (max-width: 800px) {
  .ts-he-deatils--table tr td {
    border-top: none; }
    .ts-he-deatils--table tr td:first-child {
      border-top: 1px solid #ddd; } }

@media only screen and (max-width: 1380px) {
  .ts-searching-container form {
    font-size: 0.9em; } }

@media only screen and (max-width: 800px) {
  .ts-searching-container .ts-searching-col {
    width: 50%;
    box-sizing: border-box;
    display: block;
    float: right;
    margin-bottom: 10px; }
  .ts-searching-container .ts-searching-col label,
  .ts-searching-container .ts-input-searching {
    display: block; }
  .ts-searching-container form input[type="submit"] {
    width: 100%;
    margin-top: 20px; } }

@media only screen and (max-width: 450px) {
  .ts-searching-container .ts-searching-col {
    width: 100%; }
  .ts-searching-container form input[type="submit"] {
    padding: 8px 25px; } }

@media print {
  header, footer, .ts-matnas--nav-bar, #ts-side--con {
    display: none; } }
