
// Hog & events groups
//-----------------------------------------

.ts-he-deatils--table {
    tr {
        .ts-he-deatils--table-name {
            @for $i from 1 through length($ts-mc-all) {
                &.ts-mc-#{$i}--bc {
                    background: lighten(nth($ts-mc-all, $i), 28%);
                    color: darken(nth($ts-mc-all, $i), 28%);
                }
            } 
        }
        
        td {
            padding-left: 5px;
            padding-right: 5px;
            border-top: 1px solid #ddd;     
            
            .ts-he-deatils--button {
                padding: 4px 20px;
            }
        }
        
        &:first-child td {
            border-top: none;   
        }
    }
}

.ts-he-deatils--title {
    color: #fff;
    font-size: 1.1em;
    padding: 5px 10px;
}