
// Page matnas
//-----------------------------------------

.ts-headline--strip.matnas-page {
    height: 620px;
}

.matnas-page-inside {
    height: 220px;
}

.ts-matnas--nav-bar {
    display: table;
    width: calc(70% - 30px);
    background: #fff;
    border-radius: 0 0 5px 5px;
    padding: 15px 15px;
    
    .nav-bar--logo {
        display: table-cell;
        vertical-align: middle;
        height: 60px;
        
        h1, h2 {
            display: inline;
            vertical-align: middle;
            height: 60px;
            line-height: 35px;
            border-right: 2px solid rgb(178, 178, 178);
            padding: 0 15px;
            margin: 0 15px;
        }
        
        img {
            display: inline;
            vertical-align: middle;
            width: auto;
            height: 100%;
        }
    }
    
    .nav-bar--nav {
        display: table-cell;
        vertical-align: middle;
        height: 60px;
        line-height: 35px;
        
        li {
            margin-left: 5px;
        }
        
        a {
            z-index: 1;
            position: relative;
            color: #fff;
            background: transparent;
            padding: 4px 20px;
            
            &:before {
                z-index: -1;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 3px;
                @include skew(-30deg, 0deg);
                @include transition(ease, .2s);
            }
            
            @for $i from 1 through length($ts-mc-all) {
                &.ts-mc-#{$i}--bc:before {
                    background-color: nth($ts-mc-all, $i);
                }
            }
            
            @for $i from 1 through length($ts-mc-all) {
               &.ts-mc-#{$i}--bc:hover:before {
                    background-color: darken(nth($ts-mc-all, $i), 10%);
                }
            }
        }
    }
}