
// Page searching
//-----------------------------------------

.ts-searching-box {
    .block-border--content {
        float: right;
        padding: 20px;
    }
}

.ts-searching-container {
    form {
        display: table;
        width: 100%;
        
        input[type="submit"] {
            color: #fff;
            font-weight: bold;
            padding: 6px 25px;
            border-radius: 3px;
        }
    }
    
    .ts-searching-col {
        display: table-cell;
        vertical-align: middle;
        padding: 0 10px;
        
        label {
            font-size: 1.05em;
        }
    }
    
    .ts-submit-col {
        text-align: left;
        vertical-align: bottom;
    }
    
    .ts-input-searching {
        z-index: 1;
        position: relative;
        display: inline-block;
        margin-right: 5px;
        
        &:after {
            z-index: -1;
            content: "\f107";
            position: absolute;
            top: 50%;
            left: 4px;
            background: #ddd;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius: 3px;
            @include transform(translate(0%, -50%));
        }
        
        select {
            width: 100%;
            border: 1px solid #b5b5b5;
            padding: 6px 10px;
            padding-left: 30px;
            border-radius: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}