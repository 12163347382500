
// Page info
//-----------------------------------------

.ts-info--content {
    float: right;
    width: 100%;
    
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.content-images {
    margin-bottom: 20px;
    .content-images--image {
        float: right;
        width: 25%;
        
        img {
            display: block;
            width: 95%;
            margin: 0 auto;
            border: 1px solid #cccccc;
            border-radius: 4px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);    
            overflow: hidden;
        }
        
        &:last-child {
            margin-left: 0%;
        }
    }
    
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}
