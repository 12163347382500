
// Page tabs
//-----------------------------------------

.ts-tabs-nav {
    
    .ts-tabs-nav--list {
        float: right;
        width: 100%;
        
        ul {
            @extend .ts-tabs-nav--list;
            
            li {
                float: right;
                width: 33.333%;
                box-sizing: border-box;
                padding: 20px 5px 0 5px; 
                &:nth-child(3n+1) {
                    padding: 20px 0 0 10px;   
                }
                &:nth-child(3n+3) {
                    padding: 20px 10px 0 0;   
                }
                
                a, .ts-tabs-nav--item {
                    width: 100%;
                    height: 90px;
                    float: right;
                    text-align: center;
                }
                
                .ts-tabs-nav--item-i {
                    float: right;
                    width: 40%;
                    height: 100%;
                    
                    .block-border {
                        position: relative;
                        height: 100%;
                        overflow: hidden;
                    }
                    
                    img {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 50%;
                        margin: auto;
                        height: 100%;
                        width: auto;
                        @include transform(translate(-50%,0));
                        @include transition(cubic-bezier(0.05, 0.7, 0.4, 1), .15s);
                    }
                }
                
                .ts-tabs-nav--item-t {
                    box-sizing: border-box;
                    float: left;
                    width: 60%;
                    height: 100%;
                    padding-right: 10px;
                    font-size: 1.05em;
                    
                    .block-border {
                        display: table;
                        height: 100%;
                        @include transition(ease, .15s);
                        @for $i from 1 through length($ts-mc-all) {
                            &.ts-mc-#{$i}--bc {
                                background: lighten(nth($ts-mc-all, $i), 25%);
                                color: darken(nth($ts-mc-all, $i), 30%);
                            }
                        }
                    }
                }
                
                .block-border--content {
                    display: table-cell;
                    vertical-align: middle;
                    line-height: 20px;
                }
                
                a:hover {
                    .block-border {
                        @for $i from 1 through length($ts-mc-all) {
                            &.ts-mc-#{$i}--bc {
                                background: nth($ts-mc-all, $i);
                                color: #fff;
                            }
                        }
                    }
                    
                    img {
                        height: 130%;
                    }
                }
            }
        }
    }
}