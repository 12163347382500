
// Inputs
//----------------------------------------



.ts-input {
    z-index: 1;
    position:relative;
    display: block;
    width: 45%;
    height: auto;
    float: right;
    margin: 5px 2.5%;
    background: transparent;
    
    &.input-select:after {
        z-index: -1;
        font-family: 'FontAwesome';
        content: "\f107";
        position: absolute;
        top: 22px;
        left: 0;
        font-size: 20px;
        width: 35px;
        height: 35px;
        border-radius: 5px 0 0 5px;
        color: #fff;
        text-align: center;
        line-height: 35px;
        background: #cccccc;
    }
    
    @for $i from 1 through length($ts-mc-all) {
        &.input-select.ts-mc-#{$i}--bc:after {
            background: darken(nth($ts-mc-all, $i), 10%);
        }
    }
    
    &.input-long {
        width: 95%;
    }
    
    &.input-submit {
        float: left;
        text-align: left;
        
        > input {
            cursor: pointer;
            width: 47.5%;
            height: 35px;
            border-radius: 5px;
            color: #fff;
            background: rgb(175, 175, 175);
            @include transition(ease, .2s);
            
            &:hover {
               background: #9b9b9b; 
            }
            
            &:first-child {
                margin-left: 2.5%;
            }
        }
        
        @for $i from 1 through length($ts-mc-all) {
            &.ts-mc-#{$i}--bc > input[type="submit"]{
                background: darken(nth($ts-mc-all, $i), 10%);
                
                &:hover {
                    background: darken(nth($ts-mc-all, $i), 20%);
                }
            }
        }
    }
    
    > input[type="text"], textarea, select,
    input[type="email"], input[type="tel"] {
        width: 90%;
        height: 35px;
        padding: 0 5%;
        border: 1px solid #cccccc;
        border-radius: 5px;
    }
    
    select {
        width: 100%;
    }
    
    textarea {
        height: 100px;
        padding: 20px 5%;
    }
    
    @for $i from 1 through length($ts-mc-all) {
        &.ts-mc-#{$i}--bc > input:focus,
        &.ts-mc-#{$i}--bc > textarea:focus,
        &.ts-mc-#{$i}--bc > select:focus {
            border-color: darken(nth($ts-mc-all, $i), 10%);
        }
    }
    
    &.input-checkbox {
        
        > label {
            position: relative;
            top: 0%;
            right: 0%;
            margin-right: 10px;
            @include translate(0, 0);
        }
        
        > input {
            float: right;
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #cccccc;
        }

        > input:checked {
            background: #cccccc;
        }
        
        @for $i from 1 through length($ts-mc-all) {
            &.ts-mc-#{$i}--bc > input:checked {
                border-color: darken(nth($ts-mc-all, $i), 10%);
                background: darken(nth($ts-mc-all, $i), 10%);
            }
        }
    }
}