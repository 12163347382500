
// Page department
//-----------------------------------------

.ts-department-info {
    float: right;
    width: 60%;
}

.ts-department-info:after {
    content: "";
    display: block;
    clear: both;
}

.swiper-container.ts-gallery-department {
    float: left;
}

.ts-department-nav {
    float: right;
    width: 100%;
    margin-top: 20px;
    
    .ts-content-title {
        border-bottom: 1px solid #cccccc;
        font-size: 1.4em;
        background: transparent;
        margin-bottom: 10px;
        
        @for $i from 1 through length($ts-mc-all) {
            &.ts-mc-#{$i}--bc {
                color: darken(nth($ts-mc-all, $i), 15%);
            }
        }
    }
}

.block-border--title {
    @for $i from 1 through length($ts-mc-all) {
        &.ts-mc-#{$i}--bc {
            color: darken(nth($ts-mc-all, $i), 28%);
            background: lighten(nth($ts-mc-all, $i), 22%);
        }
    }
}

.ts-department-nav--list {
    float: right;
    width: 100%;
    
    ul {
        @extend .ts-department-nav--list;
        
        li {
            width: 20%;
            padding: 10px;
            text-align: center;
            box-sizing: border-box;
            
            .ts-department-nav--item {
                color: #fff;
                font-weight: 700;
                padding: 20% 0;
                border-radius: 4px;
                border: 1px solid #cccccc;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                @include transition(ease, .2s);
                
                @for $i from 1 through length($ts-mc-all) {
                    &.ts-mc-#{$i}--bc {
                        background: nth($ts-mc-all, $i);
                    }
                    &.ts-mc-#{$i}--bc i {
                         color: darken(nth($ts-mc-all, $i), 10%);
                    }
                }
                
                i {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    font-size: 2em;
                    color: black;
                    background: #fff;
                    border-radius: 100%;
                    @include transition(ease, .5s);
                }
                
                &:hover {
                    @for $i from 1 through length($ts-mc-all) {
                        &.ts-mc-#{$i}--bc {
                            background: darken(nth($ts-mc-all, $i), 10%);
                        }
                    }
                    i {
                        @include transform(rotateY(180deg));
                    }
                }
                
                .ts-department-nav--item-t {
                    height: 25px;
                    line-height: 1.1em;
                    font-size: 1.05em;
                    padding: 0 10px;
                    margin-top: 10px;
                }
            }
        }
    }
}
