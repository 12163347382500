
// Page home
//-----------------------------------------

// Events container

#ts-main--events {
    z-index: -1;
}

.ts-main--title {
    z-index: 1;
    display: inline-block;
    position: relative;
    padding: 4px 55px 4px 25px;
    color: #fff;
    background: transparent;
    
    span {
        position: absolute;
        top: 0;
        right: 0px;
        width: 40px;
        height: 100%;
        line-height: 35px;
        text-align: center;
        border-radius: 0 5px 0 0;
        background: $ts-mc-d;
    }
}

.ts-main--title:after {
    content: '';
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 6px 50px 0 0;
    @include skew(-35deg, 0deg)    
}

@for $i from 1 through length($ts-mc-all) {
    .ts-main--title.ts-mc-#{$i}--bc:after {
        background-color: nth($ts-mc-all, $i);
    }
}

.events--item-con {
    display: inline-block;
    position: relative;
    width: 105%;
    right: -2.5%;
    margin-bottom: 60px;
    
    .events--item-box {
        display: inline-block;
        float: right;
        width: 25%;
        
        .events--item {
            position: relative;
            display: inline-block;
            width: 80%;
            height: auto;
            padding: 10%;
            
            .events-item-box--title {
                margin-top: 10px;
                line-height: 25px;
                min-height: 50px;
                font-size: 1.25em;
                color: $ts-mc-d;
            }
            
            .events-item-box--con {
                z-index: 1;
                position: relative;
                overflow: hidden;
                height: 300px;
                border-radius: 10px;
                
                img {
                    z-index: -1;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    height: 100%;
                    width: auto;
                    transform: translate(-50%);
                    -webkit-transform: translate(-50%);
                    -moz-transform: translate(-50%);
                    -ms-transform: translate(-50%);
                    -o-transform: translate(-50%);
                }
                
                .events-item-box--content.active {
                    top: 10px;
                }
                
                .events-item-box--content {
                    position: absolute;
                    top: 250px;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    background: rgba(0, 0, 0, 0.8);
                    font-size: 13pt;
                    @include transition(ease, 0.4s);
                    
                    > div {
                        height: auto;
                        line-height: 40px;
                        padding: 0 0 0 5%;
                        border-bottom: 1px solid rgba(255,255,255,0.5);
                        
                        > .fa {
                            float: right;
                            width: 45px;
                            height: 40px;
                            margin-left: 2.5%;
                            line-height: 40px;
                            text-align: center;
                            font-size: 1.2em;
                        }
                        
                        > .fa.fa-map-marker {
                            height: 65px;
                            line-height: 20px;
                        }
                        
                        > .fa.fa-ils {
                            display: none;
                        }
                        
                        span a {
                            color: #fff;
                        }
                        
                        span a:after {
                            content: '\f104';
                            margin-right: 10px;
                            @include transition(ease, 0.15s);
                        }
                        
                        span a:hover:after {
                            margin-right: 15px;
                        }
                        
                        .events-item-box--trigger {
                            color: #fff;
                        }
                        
                        .events-item-box--trigger.active:before {
                            content: '\f00d';
                        }
                    }
                    
                    > div:nth-child(3) {
                        line-height: 20px;
                        padding: 15px 0 15px 5%;
                    }
                    
                    > div:last-child {
                        border-bottom: 0px solid rgba(255,255,255,0.5);
                    }
                }
            }
            
            .events-item-box--link {
                display: inline-block;
                width: 89.5%;
                height: 35px;
                line-height: 35px;
                padding: 0 5%;
                margin-top: 10px;
                border-radius: 5px;
                background: transparent;
                @include transition(ease, 0.15s);
            }
            
            @for $i from 1 through length($ts-mc-all) {
                .events-item-box--link.ts-mc-#{$i}--bc {
                    color: darken( nth($ts-mc-all, $i), 10% );
                    border: 1px solid nth($ts-mc-all, $i);
                    box-shadow: 0px 0px 3px 0px lighten( nth($ts-mc-all, $i), 20% );
                }
                
                .events-item-box--link.ts-mc-#{$i}--bc:hover {
                    box-shadow: 0px 0px 5px 0px nth($ts-mc-all, $i);
                }
            }
            
            .events-item-box--link:after {
                content: '\f104';
                font-size: 1.3em;
                margin-left: 10px;
                float: left;
                @include transition(ease, 0.15s);
            }
            
            .events-item-box--link:hover:after {
                margin-left: 0px;
            }
            
            .events-item-box--con:before,
            .events-item-box--con:after {
                    content: '';
                    position: relative;
                    z-index: 1;
                    display: block;
                    width: 100%;
                    height: 10px;
            }
            
            @for $i from 1 through length($ts-mc-all) {
                .events-item-box--con.ts-mc-#{$i}--bc:before,
                .events-item-box--con.ts-mc-#{$i}--bc:after {
                    background-color: nth($ts-mc-all, $i);
                }
            }
            
            .events-item-box--con:after {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }    
}

// Classes searching

#ts-main--classes {
    position: relative;
    top: -42.5px;
    
    #classes--search-panel {
        display: inline-block;
        width: 100%;
        height: auto;
        padding: 2.5% 0;
        background: transparent;
        
        #search-panel--nav a {
            overflow: hidden;
            z-index: 1;
            position: relative;
            float: right;
            width: 25%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-weight: 700;
            color: #fff;
        }
        
        #search-panel--nav a:before {
            z-index: -1;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 90%;
            height: 100%;
            margin: 0 5%;
            border-radius: 5px 8px 0 0;
            background: $ts-mc-d;
            @include skew(-30deg, 0deg);
            @include transition(ease, 0.15s);
        }
        
        #search-panel--nav a:first-child:before {
            right: -10%;
            width: 100%; 
        }
        
        #search-panel--nav a:first-child {
            border-radius: 0 8px 0 0;
        }
        
        #search-panel--nav a:last-child:before {
            left: -10%;
            width: 100%; 
        }
        
        #search-panel--nav a:last-child {
            border-radius: 8px 0 0 0;
        }
        
        #search-panel--nav a.active {
            cursor: auto;
        }
        
        #search-panel--con {
            overflow: hidden;
            display: inline-block;
            width: 100%;
            height: 220px;
            border-radius: 0 0 8px 8px;
            
            .screen {
                position: relative;
                z-index: -1;
                opacity: 0;
                @include translate(0px, 30px);
                @include transition(cubic-bezier(0.4, 0.1, 0, 1.6), 0.35s);
            }
            
            .screen.active {
                z-index: 1;
                opacity: 1;
                @include translate(0px, 0px);
            }
        }
    }
    
    @for $i from 1 through length($ts-mc-all) {
        #classes--search-panel.ts-mc-#{$i}--bc {
            
            #search-panel--con,
            #search-panel--nav a.active:before {
                background-color: nth($ts-mc-all, $i);
            }
            
            #search-panel--nav a:hover:before {
                background-color: darken( nth($ts-mc-all, $i), 5% );
            }
            
            #search-panel--nav a.active:hover:before {
                background-color: nth($ts-mc-all, $i);
            }
        }
    }
}

// Searching category

.screen {
    
    .screen--position {
        position: absolute;
        top: 0;
        right: 0;
    }
    
    .ts-hogim--panel-title {
        color: #fff;
        font-weight: bold;
        font-size: 1.1em;
        margin-bottom: 10px;
        position: relative;
        top: -20px;
    }
    
    #ts-search--place, #ts-search--date {
        padding: 10px 2.5%;
        
        .form--bar {
            position: absolute;
            top: 117.5%;
            right: 0;
            width: 95%;
            margin: 0 2.5%;
        }
    }
    
    .ts-hogim--panel {
        display: inline-block;
        width: 95%;
        padding: 40px 2.5%;
        text-align: center;
        
        .form-fields {
            
            fieldset {
                border: none;
                height: 100px;
                color: #fff;
                font-size: 1.1em;
                
                legend {
                    display: block;
                    text-align: right;
                    font-weight: bold;
                }
                
                .form-fields--elements {
                    display: block;
                    
                    span {
                        position: relative;
                        display: inline-block;
                        width: auto;
                        height: 25px;
                        line-height: 25px;
                        margin: 5px;
                        text-align: center;
                        
                        label {
                            display: inline-block;
                            position: relative;
                            top: 0;
                            left: 0;
                            width: auto;
                            white-space: nowrap;
                            padding: 2px 10%;
                            border-radius: 5px;
                            text-align: right;
                            background: #fff;
                            color: #333333;
                            @include transition(ease, .2s);
                            
                            &:after {
                                display: inline-block;
                                margin-right: 15px;
                                content: ' \f055';
                                @include transition(ease, .2s);
                            }
                        }
                        
                        input[type="checkbox"] {
                            position: absolute;
                            z-index: -1;
                        }
                        
                        input[type="checkbox"]:checked ~ label {
                            color: #fff;
                            background: #333333;
                            
                            &:after {
                                @include rotate(45deg);
                            }
                        }
                    }
                }
            }
        }
        
        .form--bar {
            border-top: 1px solid #fff;
            text-align: right;
            padding: 25px 0;
            color: #fff;
        
            label {
                font-weight: bold;
                font-size: 1.1em;
            }
            
            input {
                font-family: 'Alef', sans-serif;
                color: #333333;
                font-size: 1em;
                font-weight: bold;
                text-align: center;
                background: #fff;
                border-radius: 5px;
                margin: 0 10px;
                width: 120px;
                height: 30px;
            }
            
            input[type="submit"] {
                width: 150px;
                color: #fff;
                background: #333333;
                
                &:hover {
                    background: black;
                }
            }
            
            span:last-child {
                float: left;
            }
        }
    }
}

#ts-search--category {
    
    ul li {
        width: 19.8%;
        border-left: 1px solid $ts-mc-b;
        
        a {
            display: block;
            height: auto;
            padding: 10%;
            color: #fff;
            
            .category--item {
                
                .item--icon {
                    position: relative;
                    top: 0px;
                    display: block;
                    font-size: 3.7em;
                    @include transition(ease, 0.15s);
                }
                
                .item--content {
                    display: block;
                    font-size: 13pt;
                }
            }
        }
        
        a:hover {
            
            .item--icon {
                top: -15px;
            }
        }
    }
    
    ul li:last-child {
        border-width: 0px;
    }
}

#ts-search--age {
    #range {
        height: 80px;
        width: 95%;
        margin: 0 2.5%;
        margin-top: 20px;
    }
}