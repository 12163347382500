
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
	touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
	user-select: none;
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1; /* Fix 401 */
}
.noUi-connect {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}
.noUi-origin {
	position: absolute;
	height: 0;
	width: 0;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
-webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
	transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
	height: 10px;
}
.noUi-horizontal .noUi-handle {
	width: 20px;
	height: 20px;
	left: -10px;
	top: -6px;
}
.noUi-vertical {
	width: 20px;
}
.noUi-vertical .noUi-handle {
	width: 20px;
	height: 20px;
	left: -6px;
	top: -17px;
}

/* Styling;
 */
.noUi-target {
	background: #fff;
	border-radius: 10px;
}
.noUi-connect {
	background: #333333;
-webkit-transition: background 450ms;
	transition: background 450ms;
}

/* Handles and cursors;
 */
.noUi-draggable {
	cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: n-resize;
}
.noUi-handle {
	background: #333333;
	border: 3px solid #fff;
	border-radius: 20px;
}


/* Handle stripes;
 */


/* Disabled state;
 */

[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
	cursor: not-allowed;
}

/* Tooltips
 */

.noUi-tooltip {
	display: block;
	position: absolute;
	border-radius: 3px;
	background: #fff;
	color: #000;
	padding: 5px 10px;
	text-align: center;
	font-weight: bold;
}
.noUi-horizontal .noUi-tooltip {
-webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 200%;
}

.noUi-horizontal .noUi-tooltip:after {
	content: '';
  	border-left: 7px solid transparent;
  	border-right: 7px solid transparent;
  	border-top: 7px solid #fff;
	position: absolute;
	top: 100%;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

.noUi-vertical .noUi-tooltip {
-webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 200%;
}

/* Pips

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-pips {
	position: absolute;
	color: #fff;
}

/* Values;
 *
 */
.noUi-value {
	position: absolute;
	text-align: center;
}
.noUi-value-sub {
	color: #fff;
	font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
	position: absolute;
	background: transparent;
}
.noUi-marker-sub {
	background: #fff;
}
.noUi-marker-large {
	background: #fff;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
	padding: 10px 0;
	height: 80px;
	top: 100%;
	left: 0;
	width: 100%;
}
.noUi-value-horizontal {
	-webkit-transform: translate3d(-50%,50%,0);
	transform: translate3d(-50%,50%,0);
}

.noUi-marker-horizontal.noUi-marker {
	margin-left: -1px;
	width: 2px;
	height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
	height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
	height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
	padding: 0 10px;
	height: 100%;
	top: 0;
	left: 100%;
}
.noUi-value-vertical {
	-webkit-transform: translate3d(0,50%,0);
	transform: translate3d(0,50%,0);
	padding-left: 25px;
}

.noUi-marker-vertical.noUi-marker {
	width: 5px;
	height: 2px;
	margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
	width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
	width: 15px;
}
