
// Page hog & event
//-----------------------------------------

.swiper-container.ts-gallery-he {
    float: right;
}

.ts-he-info {
    float: left;
    width: 60%;
}

.ts-he-info:after {
    content: "";
    display: block;
    clear: both;
}

.ts-he-deatils {
    margin-top: 20px;
    margin-bottom: 0px;
    .ts-he-deatils--table {
        width: 100%;
        text-align: center;
        font-size: 1em;
        
        td {
            background: transparent;
            @for $i from 1 through length($ts-mc-all) {
                &.ts-mc-#{$i}--bc:before {
                   background: lighten(nth($ts-mc-all, $i), 25%);
                   color: darken(nth($ts-mc-all, $i), 30%);
                }
            } 
        }
    }
    
    .ts-he-deatils--button {
        display: inline-block;
        margin: 5px 0;
        padding: 4px 30px;
        font-weight: 700;
        color: #fff;
        border-radius: 2px;
        background: #aec742;
        @include transition(ease, .15s);
        @for $i from 1 through length($ts-mc-all) {
            &.ts-mc-#{$i}--bc {
               background: nth($ts-mc-all, $i);
            }
            &.ts-mc-#{$i}--bc:hover {
                background: darken(nth($ts-mc-all, $i), 10%);
            }
        }
        &:hover {
            background: #8fa531;
        }
    }
}