
// Loops
//-----------------------------------------

// Color classes

@for $i from 1 through length($ts-mc-all) {
    .ts-mc-#{$i}--bc {
        background: nth($ts-mc-all, $i);
    }
    
    b.ts-mc-#{$i}--bc,
    label.ts-mc-#{$i}--bc {
        background: transparent;
        color: darken(nth($ts-mc-all, $i), 10%);
    }
}