

// Media queries
//-----------------------------------------

// Home page

@media only screen and (max-width : 1560px) {
    
    .ts-main--width {
        max-width: 1280px;
    }
    
    #ts-side--con {
        width: 350px;
    }
    
    .swiper-content p {
        padding: 0 45px 0 40% !important;
    }
    
    #header-top--menu li .menu-sub {
        width: 350%;
    }
}

@media only screen and (max-width : 1380px) {
    
    .ts-main--width {
        max-width: 1080px;
    }

    #header-top--strip {
        height: 55px;
        margin-top: 10px;
    }
    
    #header-top--strip #strip-logo {
        width: 200px;
    }
    
    #header-top--menu {
        height: 35px;
        margin-bottom: 5px;
    }
    
    
    #header-top--menu li .menu-button {
        font-size: 14px;
    }
    
    #header-top--menu li:nth-child(n+2):nth-child(even) .menu-sub {
        right: -100%;
    }
    
    #header-top--nav {
        height: 25px;
        line-height: 25px;
        font-size: 0.9em;
    }
    
    #ts-side--con #side--title {
        font-size: 0.9em;
        height: 40px;
        line-height: 40px;
    }
    
    #ts-side--con #side--title.icon-nav:before {
        width: 40px;
        height: 40px;
        line-height: 42px;
        font-size: 1.5em;
    }
    
    #ts-side--con #side--buttons {
        width: 95%;
        padding: 2.5%;
    }
    
    #ts-side--con #side--buttons a {
        height: 35px;
        line-height: 35px;
    }
    
    #ts-side--con #side--news {
        font-size: 0.9em;
    }
    
    #ts-side--con #side--search {
        padding: 5%;
    }
    
    #ts-side--con #side--nav li .side--nav-item {
        padding: 5%;
        font-size: 0.9em;
    }
    
    #ts-side--con #side--news ul li {
        line-height: 20px;
    }
    
    .swiper-container,
    .ts-main--width-right {
        width: 65%;
    }
    
    .events--item-con .events--item-box {
        width: 33.333%;
    }
    
    
    #ts-main--classes #classes--search-panel #search-panel--nav a:before {
        border-radius: 5px 5px 0 0;
        -webkit-transform: skew(0deg, 0deg);
        -moz-transform: skew(0deg, 0deg);
        -ms-transform: skew(0deg, 0deg);
        -o-transform: skew(0deg, 0deg);
        transform: skew(0deg, 0deg);
    }
    
    .screen .ts-hogim--panel .form-fields--elements span label {
        font-size: 0.9em;
        padding: 0px 10% !important;
    }
    
    .screen .ts-hogim--panel .form-fields--elements span {
        margin: 3px !important;
    }
    
}

@media only screen and (max-width : 1080px) {
    
    .goToTop {
        display: none;
    }
    
    .ts-main--width {
        max-width: 800px;
        padding: 0 5%
    }
    
    #header-top--menu li {
        width: 49.5%;
        margin: 5% 0.25%;
    }    
    
    #header-top--menu li .menu-sub {
        width: 170%;
        padding: 10% 15%;
        font-size: 0.95em;
    }
    
    #header-top--menu {
        opacity: 0;
        height: 0px;
        overflow: hidden;
    }
    
    #header-top--menu.active {
        opacity: 1;
        height: 45px;
        overflow: visible;
    }
    
    #header-top--strip {
        overflow: hidden;
    }
    
    #header-top--strip.active {
        height: 0;
    }
    
    #ts-side--con {
        width: 400px;
        left: 50%;
        top: -100%;
        transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        /* Positioning create ts-layout-func.js > in sideBar() */
        transition: cubic-bezier(0.3, 0, 0, 1.04) 0.6s;
        -webkit-transition: cubic-bezier(0.3, 0, 0, 1.04) 0.6s;
        -moz-transition: cubic-bezier(0.3, 0, 0, 1.04) 0.6s;
        -ms-transition: cubic-bezier(0.3, 0, 0, 1.04) 0.6s;
        -o-transition: cubic-bezier(0.3, 0, 0, 1.04) 0.6s;
    }
    
    #ts-side--con.active {
        z-index: 99999;
        top: 0 !important;
    }
    
    .swiper-container, .ts-main--width-right {
        width: 100%;
    }
}

@media only screen and (max-width : 650px) {
    
    .ts-mobile-hide {
        display: none;
    }
    
    .ts-mobile-show {
        display: inline-block !important;
    }
    
    .ts-headline--strip {
        height: 300px;
    }
    
    #header-top--menu li:hover .menu-sub, 
    #header-top--menu li:hover .menu-sub,
    #header-top--menu li:focus .menu-sub, 
    #header-top--menu li:focus .menu-sub{
        visibility: hidden !important;
        opacity: 0;
    }
    
    #header-top--menu li.active .menu-sub, 
    #header-top--menu li.active .menu-sub {
        visibility: visible !important;
        opacity: 1;
    }
    
    #header-top--menu li .menu-sub li {
        width: 98%;
    }
    
    #header-top--menu li .menu-sub li :before {
        left: 2%;
    }
    
    #header-top--menu li:hover .menu-sub, #header-top--menu li:focus .menu-sub {
        opacity: 1;
    }

    .menu-sub ul {
        display: block;
        width: 100%;
        max-height: 155px;
        overflow: scroll;
    }
    
    .swiper-container {
        top: 10px;
    }
    
    .swiper-content {
        height: 90px;
    }
    
    .swiper-content .swiper-content--headline {
        font-size: 20px;
        margin-right: -55px;
    }
    
    .swiper-content p {
        font-size: 13px;
        line-height: 16px;
        padding: 0 45px 0 15% !important;
    }
    
    .swiper-content .swiper-content--link {
        padding: 60px 15px 10px 15px;
        font-size: 15px;
    }
    
    .swiper-container-rtl .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        font-size: 2em;
    }
    
    .swiper-button-prev, .swiper-button-next {
        top: 30px;
        width: 30px;
        height: 80px;
        line-height: 80px;
    }
    
    .swiper-container-rtl .swiper-button-next:hover {
        padding-right: 0px;
    }
    
    .swiper-container-rtl .swiper-button-prev:hover {
        padding-left: 0px;
    }
    
    .swiper-content .swiper-content--link {
        border-radius: 0 15px 0 0;
        padding: 0px 0px 0px 0px;
        width: 40px;
    }
    
    .swiper-content .swiper-content--link:before {
        width: 100%;
        border-right: 0px solid transparent;
        border-top: 0px solid transparent;
    }
    
    .swiper-content .swiper-content--link:after {
        content: '\f067';
        bottom: 12px;
        left: 14px;
        font-size: 15px;
        color: #fff;
    }
    
    .swiper-content .swiper-content--link a {
        color: transparent;
        padding-left: 0px;
        width: 100%;
    }
    
    .ts-main--title {
        font-size: 17px;
    }
    
    .events--item-con .events--item-box {
        width: 50%;
    }
    
    .events--item-con .events--item-box .events--item {
        width: 90%;
        padding:  10% 5%;
    }
    
    .events--item-con .events--item-box .events--item .events-item-box--con {
        height: 200px;
    }
    
    .events--item-con .events--item-box .events--item .events-item-box--title {
        font-size: 0.9em;
        line-height: 20px;
        min-height: 40px;
    }
    
    .events--item-con .events--item-box .events--item .events-item-box--link {
        height: 30px;
        line-height: 30px;
        font-size: 0.9em;
    }
    
    #ts-main--classes #classes--search-panel #search-panel--con {
        border-radius: 5px;
    }
    
    #ts-main--classes #classes--search-panel {
        padding: 5% 0;
    }
    
    #ts-search--category {
        padding: 40px 2.5%;
    }
    
    #ts-search--category ul li {
        border-width: 0;
    }
    
    #ts-search--category ul li a .category--item .item--icon {
        font-size: 2.5em;
    }
    
    #ts-search--category ul li a .category--item .item--content {
        font-size: 11pt;
    }
    
}

@media only screen and (max-width : 570px) {
    
    #ts-main--classes #classes--search-panel #search-panel--con {
        height: 300px;
    }
    
    .controler {
        font-size: 0.8em;
    }
    
    #ts-search--age #range {
        margin-top: 60px;
    }
    
    #ts-search--category ul li {
        width: 33.33%;
    }
    
    .screen .form--bar {
        margin-top: 40px;
    }
    
    .screen #ts-search--place .form--bar, .screen #ts-search--date .form--bar {
        top: 180%;
    }
    
}

@media only screen and (max-width : 480px) {
    #ts-side--con {
        width: 100%;
    }
    
    #ts-side--con #side--buttons {
        margin: 0;
    }
    
    #ts-side--con #side--buttons a {
        width: 47.5%;
    }
    
    #ts-side--con #side--news {
        display: none;
    }
    
    #ts-side--con #side--search form #search--type-submit {
        width: 25%;
    }
    
}

// Matnas page

@media only screen and (max-width : 1560px) {
    
    .ts-matnas--nav-bar .nav-bar--logo h1 {
        font-size: 1.6em;
    }
    
    .ts-matnas--nav-bar .nav-bar--nav a {
        padding: 4px 15px;
        font-size: 1em;
    }
    
}

@media only screen and (max-width : 1380px) {
    
    .ts-matnas--nav-bar {
        width: calc(65% - 60px);
        padding: 15px 30px;
    }
    
    .ts-matnas--nav-bar,
    .ts-matnas--nav-bar .nav-bar--logo,
    .ts-matnas--nav-bar .nav-bar--nav {
        display: block;
    }
    
    .ts-matnas--nav-bar .nav-bar--nav {
        height: 35px;
        margin-top: 10px;
    }
    
    .ts-matnas--nav-bar .nav-bar--logo h1 {
        font-size: 1.8em;
    }
}

@media only screen and (max-width : 1080px) {
    
    .ts-matnas--nav-bar {
        width: calc(100% - 60px);
    }
}

@media only screen and (max-width : 650px) {
    
    .page-title {
        height: 40px;
    }
    
    .page-title h1 {
        font-size: 1.2em;
    }
    
    .ts-headline--strip.matnas-page {
        height: 540px;
    }
    
    .ts-matnas--nav-bar .nav-bar--nav {
        display: inline-block;
        height: auto;
        text-align: center;
    }
    
    .ts-matnas--nav-bar .nav-bar--logo {
        height: auto;
        text-align: center;
    }
    
    .ts-matnas--nav-bar .nav-bar--logo h1 {
        display: block;
        height: auto;
        border-width: 0;
        padding: 0 0px;
        margin: 0 0px;
        font-size: 1.5em;
    }
    
    .ts-matnas--nav-bar .nav-bar--logo img {
        width: 70px;
        height: auto;
        display: block;
        margin: 0 auto;
    }
    
    .ts-matnas--nav-bar .nav-bar--logo h2 {
        padding: 0;
        margin: 0;
        border: none;
    }
    
    .ts-matnas--nav-bar .nav-bar--nav li {
        float: none;
    }
    
    .ts-matnas--nav-bar .nav-bar--nav a {
        font-size: 0.9em;
    }
    
    .ts-matnas--nav-bar .nav-bar--nav a:before {
        -webkit-transform: skew(0deg, 0deg);
        -moz-transform: skew(0deg, 0deg);
        -ms-transform: skew(0deg, 0deg);
        -o-transform: skew(0deg, 0deg);
        transform: skew(0deg, 0deg);
    }
    
    .content-images .content-images--image:nth-child(n+2) {
        display: none;
    }
    
    .content-images .content-images--image {
        width: 100%;
    }
    
}

// Contact page

@media only screen and (max-width : 650px) {
    
    .ts-contact--map {
        width: 100%;
        margin-bottom: 20px;
    }
    
    .ts-contact--content {
        width: 100%;
    }
    
    .ts-contact--content .block-border {
        font-size: 0.9em;
    }
    
}

@media only screen and (max-width : 480px) {
    
    .ts-contact--map iframe {
        height: 100px;
    }
    
    .ts-input {
        width: 95%;
    }
    
}

// Department page
  
@media only screen and (max-width : 1380px) {
    
    .ts-department-nav--list ul li {
        width: 25%;
    }
    
    .swiper-container.ts-gallery .swiper-button-next,
    .swiper-container.ts-gallery .swiper-button-prev {
        top: 70px;
    }
    
}

@media only screen and (max-width : 800px) {
    
    .ts-department-info, 
    .ts-he-info,
    .swiper-container.ts-gallery {
        width: 100%;
    }
    
    .swiper-container.ts-gallery {
        margin-bottom: 20px;
    }
    
    .swiper-container.ts-gallery .swiper-button-next,
    .swiper-container.ts-gallery .swiper-button-prev {
        top: 110px;
    }
    
}


@media only screen and (max-width : 650px) {
    
    .ts-department-nav--list ul li {
        width: 33.333%;
    }

}

@media only screen and (max-width : 570px) {
    
    
    .swiper-container.ts-gallery .swiper-button-next,
    .swiper-container.ts-gallery .swiper-button-prev {
        top: 45px;
    }
    
    .block-border.half-width {
        width: calc(100% - 2px);
    }
    
    .block-border.half-width:nth-child(odd) {
        margin-left: 0%;
    }
    
    .ts-department-nav--list ul li .ts-department-nav--item i {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 1.6em;
    }
    
}

@media only screen and (max-width : 480px) {
    
    .ts-department-nav--list ul li {
        width: 50%;
        padding: 5px;
    }
    
}

// Tabs page

@media only screen and (max-width : 1380px) {
    .ts-tabs-nav .ts-tabs-nav--list ul li {
        width: 50%;
    }
    
    .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(3n+1),
    .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(3n+3) {
        padding: 20px 5px 0 5px; 
    }
    
    .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(2n+2) {
        padding: 20px 10px 0 0; 
    }

    .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(2n+1) {
        padding: 20px 0 0 10px; 
    }
}

@media only screen and (max-width : 730px) {
    .ts-tabs-nav .ts-tabs-nav--list ul li {
        width: 100%;
        font-size: 0.95em;
    }
    
    .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(2n+2) {
        padding: 20px 0 0 0; 
    }

    .ts-tabs-nav .ts-tabs-nav--list ul li:nth-child(2n+1) {
        padding: 20px 0 0 0; 
    }
}

// Hog & Event page

@media only screen and (max-width : 800px) {

    .ts-he-deatils--table .ts-padding-tb {
      padding: 0; 
    }
    
    .ts-he-deatils--table, 
    .ts-he-deatils--table thead,
    .ts-he-deatils--table tbody, 
    .ts-he-deatils--table th, 
    .ts-he-deatils--table td, 
    .ts-he-deatils--table tr {
        display: block;
    }
    
    .ts-he-deatils--table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    
    .ts-he-deatils--table tr td {
        border-bottom: 1px solid #cccccc;
        position: relative;
        padding: 15px 45% 15px 0;
    }
    
    .ts-he-deatils--table td:last-child {
        border: none;
    }
    
    .ts-he-deatils--table td:before {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 0;
        width: 40%;
        height: 100%;
        border-left: 1px solid #cccccc;
        padding-right: 10px;
        white-space: nowrap;
        transform: translate(0%, -50%);
        -webkit-transform: translate(0%, -50%);
        -moz-transform: translate(0%, -50%);
        -ms-transform: translate(0%, -50%);
        -o-transform: translate(0%, -50%);
    }
    
    .ts-he-deatils--table td:before {
        content: attr(data-th);
        font-family: 'Alef', sans-serif;
        font-weight: 700;
    }
    
    .ts-he-deatils--table .ts-th-nomobile {
        padding: 15px 0 15px 0;
    }

    .ts-he-deatils--table .ts-th-nomobile .ts-he-deatils--button {
        padding: 6px 0;
        width: 90%;
    }
    
    .ts-he-deatils--table .ts-th-nomobile:before {
        display: none;
    }
    
}

@media only screen and (max-width : 570px) {
    
    .ts-he-deatils .ts-he-deatils--table {
        font-size: 0.95em;
    }
    
}

// Jobs page

@media only screen and (max-width : 800px) {
    
    .ts-jobs--table tr:nth-child(n+2) td {
        border-top: none;
    }
    
    .ts-jobs--table tr:nth-child(n+2) td:first-child {
        border-top: 1px solid #ddd;
    }
    
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-right,
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-left {
        padding: 0px 2.5% 15px 2.5%;
        width: 100%;
    }
    
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-left {
        border-right: none;
        background: transparent;
    }
    
    .ts-jobs--table tr.active td:last-child {
        border-bottom: none;
    }
    
    .ts-jobs--table .ts--table-hidden-box .ts--table-hidden-content {
        margin-bottom: 10px;
    }
    
}

// Hog & events groups

@media only screen and (max-width : 1380px) {
    
    .ts-he-deatils .ts-he-deatils--table {
        font-size: 0.95em;    
    }
    
}

@media only screen and (max-width : 800px) {

    .ts-he-deatils--table {
        tr {
            td {
                border-top: none;
                
                &:first-child {
                    border-top: 1px solid #ddd;
                }
            }
        }
    }

}

// Index Hogim

@media only screen and (max-width : 1380px) {
    
    .ts-searching-container form {
        font-size: 0.9em;
    }
    
}

@media only screen and (max-width : 800px) {
    
    .ts-searching-container .ts-searching-col {
        width: 50%;
        box-sizing: border-box;
        display: block;
        float: right;
        margin-bottom: 10px;
    }
    
    .ts-searching-container .ts-searching-col label,
    .ts-searching-container .ts-input-searching {
        display: block;
    }
    
    .ts-searching-container form input[type="submit"] {
        width: 100%;
        margin-top: 20px;
    }
    
}

@media only screen and (max-width : 450px) {
    
    .ts-searching-container .ts-searching-col {
        width: 100%;
    }
    
    .ts-searching-container form input[type="submit"] {
        padding: 8px 25px;
    }
    
}

// Print!

@media print {
    header, footer, .ts-matnas--nav-bar, #ts-side--con {
        display: none;
    }
}